'use strict';

var $ = require('jquery');

var MobileDetect = require('mobile-detect');

var md = new MobileDetect(navigator.userAgent || navigator.vendor || window.opera);

if (md.phone() || md.tablet()) {
  $('body').addClass('mobile');
} else {
  $('body').removeClass('detecting-mobile');
}

module.exports = md;
