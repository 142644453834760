'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($rootScope, $scope, $stateParams, $state, CartService) {
  var vm = this;

  vm.cart = CartService.cart();

  vm.increment = function(item, quantity){
    CartService.updateItemQuantity(item, quantity, $rootScope.prices);
  };

  vm.addItem = function addItem(product) {
    CartService.addItem(product, $rootScope.prices);
  };
};
