'use strict';

var _ = require('lodash');

var CheckoutStepsController = /*@ngInject*/ function CheckoutStepsController($scope, order) {
  var vm = this;

  vm.order = order;
};

module.exports = CheckoutStepsController;
