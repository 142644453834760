'use strict';

module.exports = /*@ngInject*/ function($q, $http) {

  function create(items){
    return $http.post('/api/checkout/orders', {items: items})
      .then(function(response){
        return response.data;
      });
  }

  function save(id, params){
    return $http.patch('/api/checkout/orders/' + id, {order: params})
      .then(function(response){
        return response.data;
      });
  }

  function get(id){
    return $http.get('/api/checkout/orders/'+id).then(function(response){
      return response.data;
    })
  }

  function status(id){
    return $http.get('/api/checkout/orders/' + id + '/status').then(function(response){
      return response.data;
    })
  }

  return {
    create: create,
    save: save,
    get: get,
    status: status
  }
};
