'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($rootScope, $scope, CartService) {
  var vm = this;

  $rootScope.prices = $scope.prices;

  vm.cart = CartService.cart();
};
