'use strict';

module.exports = /*@ngInject*/ function($q, $http) {

  function get(id, token){
    return $http.get('/api/orders/' + id + '?token=' + token).then(function(response){
      return response.data;
    })
  }

  function getInvoice(id, token){
    return $http.get('/api/orders/' + id + '/invoice?token=' + token).then(function(response){
      return response.data;
    })
  }

  return {
    get: get,
    getInvoice: getInvoice
  }
};
