'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($rootScope, $scope, $stateParams, $state, $previousState, CartService, CheckoutsService) {
  var vm = this;

  vm.cart = CartService.cart();

  vm.back = function(){
    var previous = $previousState.get();
    if (previous && previous.state && _.startsWith(previous.state.name, 'fyn.store')) {
      $previousState.go();
    } else {
      $state.go('fyn.store.genome', { option: 'classic' })
    }
  };

  vm.increment = function(item, quantity){
    CartService.updateItemQuantity(item, quantity, $rootScope.prices);
  };

  vm.checkout = function() {
    CheckoutsService.create(vm.cart.items).then(function(order){
      $state.go('fyn.checkout.steps.infos', {id: order.id});
    });
  }
};
