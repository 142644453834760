'use strict';

var _ = require('lodash');

var CheckoutSuccessController = /*@ngInject*/ function CheckoutSuccessController($scope, $state, order, CartService) {
  var vm = this;

  vm.order = order;

  vm.error = order.status == 'failed';

  if (!vm.error) {
    CartService.reset();
  }
};

module.exports = CheckoutSuccessController;
