'use strict';

var _ = require('lodash');

var CheckoutPaymentController = /*@ngInject*/ function CheckoutPaymentController($scope, $stateParams, $state, $q, $http, $timeout, $window, $interpolate, order, shippingCountryInfo, spinnerService) {
  var vm = this;

  vm.order = order;

  vm.disablePaypal = false;

  vm.order.payment = 'paypal';

  vm.paymentError = $stateParams.error || order.payment_status == 'failed';

  var data = {
    recipient: vm.order.shipping_address.first_name + ' ' + vm.order.shipping_address.last_name + '\n' + vm.order.shipping_address.company,
    street: vm.order.shipping_address.address + '\n' + vm.order.shipping_address.apt,
    postalcode: vm.order.shipping_address.postal_code,
    city: vm.order.shipping_address.city,
    region: vm.order.shipping_address.state,
    region_short: vm.order.shipping_address.state,
    country: shippingCountryInfo.name,
    phone: vm.order.shipping_address.phone
  };

  vm.shipping_address = $interpolate(shippingCountryInfo.address_format + '\n{{phone}}')(data).split('\n');

  function submit_form(data) {
    var form = document.createElement("form");

    form.method = "POST";
    form.action = data.action;

    _.forEach(data.params, function(value, name) {
      var input = document.createElement("input");
      input.type = 'hidden';
      input.value = value;
      input.name = name;

      form.appendChild(input);
    });

    document.body.appendChild(form);

    form.submit();
  }

  function wait_for_processing(order, retries, fail = ''){
    function waiting(order, retries){
      var deferred = $q.defer();

      function check_payment_status(order, retries){
        if (retries == 0) {
          deferred.reject({ status: 'failed' });
          return
        }

        $http.get('/api/checkout/orders/' + order.id + '/payment/status')
          .then(function(response){
            if (response.data.status == 'processing') {
              $timeout(function() { check_payment_status(order, retries - 1) }, 500);
            } else if (response.data.status == 'redirect' || response.data.status == 'post' || response.data.status == 'paypal'){
              deferred.resolve(response.data);
            } else {
              deferred.reject({ status: 'failed' });
            }
          }).catch(function(error){
            $timeout(function() { check_payment_status(order, retries - 1) }, 500);
          });
      }

      check_payment_status(order, retries);

      return deferred.promise;
    }

    return waiting(order, retries).then(function(data){
      if (data.status == 'redirect'){
        $window.location.href = data.url;
      } else if (data.status == 'paypal'){
        if (fail == 'fail_paypal') throw Error('fail paypal');
        return data.data
      } else if (data.status == 'post'){
        submit_form(data.form);
      }
    }).catch(function(error){
      // Show error message
      submitting = false;
      spinnerService.hide('processingSpinner');
      return { status: 'failed', error }
    });
  }

  var submitting = false;
  function submit() {
    if (submitting)
      return;
    submitting = true;
    spinnerService.show('processingSpinner');

    return $http.post('/api/checkout/orders/' + vm.order.id + '/payment', {payment: {
      method: vm.order.payment,
      facturation_address_attributes: vm.order.facturation_address
    }})
      .then(function(response){ vm.order.id = response.data.id; })
      .then(function(){
        return wait_for_processing(vm.order, 30);
      }).catch(function(error){
        submitting = false;
        spinnerService.hide('processingSpinner');
        return { status: 'failed', error }
      });
  }


  vm.submit = submit;

  function paypal_setup() {
    if (!window.paypal || !window.paypal.Buttons) {
      vm.disablePaypal = true;
      vm.order.payment = 'system_pay';
      return
    }

    paypal.Buttons({
      style: {
          layout: 'horizontal',
          label: 'paypal',
          // label: 'pay',
          color: 'black',
          shape: 'rect',
          fundingicons: 'false',
          tagline: 'false',
          size: 'responsive',
          height: 50
      },
      funding: {
        disallowed: [ paypal.FUNDING.CARD, paypal.FUNDING.CREDIT, paypal.FUNDING.VENMO, paypal.FUNDING.ELV ]
      },
      createOrder: function() {
        return submit()
          .then(function(response) {
            if (response.status === 'failed') throw new Error('submit server fail: createOrder');
            return response.order_id;
          });
      },
      onApprove: function (data, actions) {
        return $http.post('/api/checkout/orders/' + vm.order.id + '/payment/paypal', { PayerID: data.payerID })
          .then(function(response){
            var result = response.data;

            if (result.status === 'redirect') $window.location.href = result.url;
            else throw Error('submit server fail: captureOrder');

            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you

            // var errorDetail = Array.isArray(response.result.details) && response.result.details[0];

            // if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
            //     return actions.restart(); // Recoverable state, per:
            //     // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            // }

            // if (errorDetail) {
            //     submitting = false;
            //     spinnerService.hide('processingSpinner');

            //     var msg = '';
            //     if (errorDetail.description) msg += '\n\n' + errorDetail.description;
            //     if (response.result.debug_id) msg += ' (' + response.result.debug_id + ')';

            //     vm.paymentError = msg ? msg : true;
            // }
          });
      },
      onCancel: function () {
        $http.post('/api/checkout/orders/' + vm.order.id + '/payment/paypal/cancel')
        submitting = false;
        spinnerService.hide('processingSpinner');
        $scope.$apply();
      },
      onError: function () {
        submitting = false;
        spinnerService.hide('processingSpinner');
        vm.paymentError = true;
        $scope.$apply();
      }
    }).render('#paypal-button');
  }

  paypal_setup();

  if(order.payment_status == 'processing'){
    submitting = true;
    $timeout(function(){
      spinnerService.show('processingSpinner');
    }, 0);
    wait_for_processing(vm.order, 30, 'fail_paypal');
  }
};

CheckoutPaymentController.resolve = {
  shippingCountryInfo: /*@ngInject*/ function(CountriesService, order){
     return CountriesService.get(order.shipping_address.country);
  }
};

module.exports = CheckoutPaymentController;
