'use strict';

require('fontfaceobserver');

var fontLoader = function() {
  if (window.document.documentElement.className.indexOf("fonts-loaded") > -1) return;

  var fonts = [];

  fonts.push(new FontFaceObserver("Gotham-Light", {}));
  fonts.push(new FontFaceObserver("Gotham-Book", {}));
  fonts.push(new FontFaceObserver("Gotham-BookItalic", {
    style: 'italic'
  }));
  fonts.push(new FontFaceObserver("Gotham-Medium", {}));
  fonts.push(new FontFaceObserver("Gotham-Bold", {
    weight: 700
  }));

  return Promise.all(fonts).then(function() {
    return window.document.documentElement.className += " fonts-loaded";
  });
};

fontLoader();
