var angular = require('angular');

module.exports = angular.module('fyn.configs', [])
  .service("$previousState",
    function($rootScope, $state) {
      var previous = null;
      var memos = {};

      var lastPrevious = null;

      $rootScope.$on('$stateChangeSuccess', function() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });

      $rootScope.$on("$stateChangeStart", function(evt, toState, toStateParams, fromState, fromStateParams) {
        lastPrevious = previous;
        previous = { state: fromState, params: fromStateParams };
      });

      $rootScope.$on("$stateChangeError", function() {
        previous = lastPrevious;
        lastPrevious = null;
      });

      $rootScope.$on("$stateChangeSuccess", function() {
        lastPrevious = null;
      });

      var $previousState = {
        get: function(memoName) {
          return memoName ? memos[memoName] : previous;
        },
        go: function(memoName) {
          var to = $previousState.get(memoName);
          return $state.go(to.state, to.params)
        },
        memo: function(memoName) {
          memos[memoName] = previous;
        }
      };

      return $previousState;
    })
  .run(function($previousState) {
    // Inject in .run so it can register $rootScope.$on.
  })
  .factory('localeHttpInterceptor',
    function($q, $location) {
      return {
        request: function(config){
          if(!config.params) config.params = {};
          config.params.locale = angular.locale;
          return config || $q.when(config);
        }
      }
    }
  ).config(function($httpProvider) {
    $httpProvider.interceptors.push('localeHttpInterceptor');
  })
  .config(function ($compileProvider){
    $compileProvider.debugInfoEnabled(true)
  }).config(function(uiGmapGoogleMapApiProvider) {
    uiGmapGoogleMapApiProvider.configure({
      key: 'AIzaSyDQ0XkWrssKgUKx0ISde_6IPSkPwFY2ClQ',
      v: '3', //defaults to latest 3.X anyhow
      libraries: 'geometry,visualization'
    });
  });
