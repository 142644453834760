var angular = require('angular');

module.exports = angular.module('fyn.directives', [])
  .directive('hasFocus', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl){
        ctrl.$focus = false;

        elm.on('focus', function(e) {
          if (ctrl.$focus)
            return;
          scope.$apply(function() {
            ctrl.$focus = true;
          })
        });

        elm.on('blur', function(e) {
          if (!ctrl.$focus)
            return;
          scope.$apply(function() {
            ctrl.$focus = false;
          })
        })
      }
    }
  })

  .directive('focusUntouch', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl){
        elm.on('focus', function(e) {
          if (ctrl.$untouched)
            return;
          scope.$apply(function() {
            ctrl.$setUntouched()
          })
        })
      }
    }
  })

  .directive('scrollSlide', function($window, $document, $timeout, $rootScope) {
    return {
      restrict: 'A',
      link: function(scope, elm, attrs, ctrl){
        var windowEl = angular.element($window);
        var down = false;
        var disabled = false;
        var handler = function() {
          if (disabled)
            return;
          var top = elm.scrollTop();
          var bottom = top + elm.outerHeight();
          if(windowEl.scrollTop() > (top + 5) && !down){
            console.log('Scrolling down!');
            disabled = true;
            down = true;
            angular.element('body, html').stop().animate({
              'scrollTop': bottom
            }, 500, 'swing', function () {
              disabled = false;
              scope.$apply(function(){
                $rootScope.showHeader = true;
              })
            });

          } else if(windowEl.scrollTop() < (bottom - 5) && down){
            console.log('Scrolling up!');
            disabled = true;
            down = false;
            scope.$apply(function(){
              $rootScope.showHeader = false;
            });
            angular.element('body, html').stop().animate({
              'scrollTop': top
            }, 500, 'swing', function () {
              disabled = false;
            });
          }
        };
        //windowEl.on('scroll', scope.$apply.bind(scope, handler));
        windowEl.on('scroll', handler);
      }
    }
  });
