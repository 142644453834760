'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($rootScope, $scope, $stateParams, $state, CartService) {
  var vm = this;

  $rootScope.prices = ($rootScope.prices = $scope.prices);

  vm.products = $scope.$parent.products;

  vm.addItem = $scope.$parent.vm.addItem;
};
