'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($rootScope, $scope, $document, $filter, $timeout, CartService) {
    var vm = this;

    vm.languageSelected = '';
    vm.language = window.location.href;
    if(vm.language.match('fr')){
        vm.languageSelected = 'fr';

        //traduction FR
        vm.traduction = {
                boards:
                    {
                        name: 'Planche',
                        board1:
                            {
                                description: 'Planche shortboard',
                                model: 'Shortboard'
                            },
                        board2:
                            {
                                description: 'Planche fish',
                                model: 'Fish'
                            },
                        board3:
                            {
                                description: 'Planche hybride',
                                model: 'Hybride'
                            },
                        board4:
                            {
                                description: 'Planche mini Malibu',
                                model: 'Mini Malibu'
                            },
                        board5:
                            {
                                description: 'Planche longboard',
                                model: 'Longboard'
                            }
                    },
                fins:
                    {
                        name: 'Dérives',
                        fin1:
                            {
                                model: 'Dynamic'
                            },
                        fin2:
                            {
                                model: 'Dynamic Carbon'
                            },
                        fin3:
                            {
                                model: 'Dynamic Longboard | SUP'
                            },
                        fin4:
                            {
                                model: 'Classique statique'
                            },
                        fin5:
                            {
                                model: 'Longboard classique statique'
                            }
                    },
                surfeur:
                    {
                        name: 'Surfeur',
                        poids:
                            {
                                name: 'Poids',
                                elem1:
                                    {
                                        name: '<40Kg',
                                        model: 'Ultra-léger - <40Kg'
                                    },
                                elem2:
                                    {
                                        name: '40-60Kg',
                                        model: 'Léger - 40-60Kg'
                                    },
                                elem3:
                                    {
                                        name: '60-80Kg',
                                        model: 'Moyen - 60-80Kg'
                                    },
                                elem4:
                                    {
                                        name: '>80Kg',
                                        model: 'Lourd - >80Kg'
                                    }
                            },
                        style:
                            {
                                name: 'Style',
                                elem1:
                                    {
                                        name: 'Débutant',
                                        model: 'Débutant'
                                    },
                                elem2:
                                    {
                                        name: 'Courbes longues',
                                        model: 'Courbes longues'
                                    },
                                elem3:
                                    {
                                        name: 'Radical',
                                        model: 'Radical'
                                    },
                                elem4:
                                    {
                                        name: 'Pro',
                                        model: 'Pro'
                                    }
                            },
                        fixation:
                            {
                                name: 'Fixation',
                                elem1:
                                    {
                                        name: 'Single',
                                        model: 'Single'
                                    },
                                elem2:
                                    {
                                        name: 'Twin',
                                        model: 'Twin'
                                    },
                                elem3:
                                    {
                                        name: 'Thruster',
                                        model: 'Thruster'
                                    },
                                elem4:
                                    {
                                        name: 'Quad',
                                        model: 'Quad'
                                    },
                                elem5:
                                    {
                                        name: 'Five',
                                        model: 'Five'
                                    }
                            },
                        vague:
                            {
                                name: 'Vague',
                                elem1:
                                    {
                                        name: '0.5m - 1.5m plat',
                                        model: '0.5m - 1.5m plat'
                                    },
                                elem2:
                                    {
                                        name: '0.5m - 1.5m creux',
                                        model: '0.5m - 1.5m creux'
                                    },
                                elem3:
                                    {
                                        name: '>1.5m plat',
                                        model: '>1.5m plat'
                                    },
                                elem4:
                                    {
                                        name: '>1.5m creux',
                                        model: '>1.5m creux'
                                    }
                            }
                    },
                textloading: '<div class="splashcont"><i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw splashimg"></i><span class="sr-only">Chargement...</span>' + '<h1>Chargement de notre configurateur...</h1><p>Ici nous établirons ensemble la meilleure configuration de dérives FYN™ pour votre surf.</p>' + '<h5>Chargement en cours, cela peut prendre un peut de temps selon la vitesse de votre connexion.</h5>'
            };
    }else{
        vm.languageSelected = 'en';

        //traduction EN
        vm.traduction = {
                boards:
                    {
                        name: 'Board',
                        board1:
                            {
                                description: 'Shortboard',
                                model: 'Shortboard'
                            },
                        board2:
                            {
                                description: 'Fish surfboard',
                                model: 'Fish'
                            },
                        board3:
                            {
                                description: 'Hybrid surfboard',
                                model: 'Hybrid'
                            },
                        board4:
                            {
                                description: 'Mini Malibu surfboard',
                                model: 'Mini Malibu'
                            },
                        board5:
                            {
                                description: 'Longboard',
                                model: 'Longboard'
                            }
                    },
                fins:
                    {
                        name: 'Fins',
                        fin1:
                            {
                                model: 'Dynamic'
                            },
                        fin2:
                            {
                                model: 'Dynamic Carbon'
                            },
                        fin3:
                            {
                                model: 'Dynamic Longboard | SUP'
                            },
                        fin4:
                            {
                                model: 'Classic static'
                            },
                        fin5:
                            {
                                model: 'Longboard classic static'
                            }
                    },
                surfeur:
                    {
                        name: 'Surfer',
                        poids:
                            {
                                name: 'Weight',
                                elem1:
                                    {
                                        name: '<40Kg | 90Lbs',
                                        model: 'Ultra-light - <40Kg | 90Lbs'
                                    },
                                elem2:
                                    {
                                        name: '40-60Kg | 90-130Lbs',
                                        model: 'Light - 40-60Kg | 90-130Lbs'
                                    },
                                elem3:
                                    {
                                        name: '60-80Kg | 130-175Lbs',
                                        model: 'Medium - 60-80Kg | 130-175Lbs'
                                    },
                                elem4:
                                    {
                                        name: '>80Kg | 175Lbs',
                                        model: 'Heavy - >80Kg | 175Lbs'
                                    }
                            },
                        style:
                            {
                                name: 'Style',
                                elem1:
                                    {
                                        name: 'Beginner',
                                        model: 'Beginner'
                                    },
                                elem2:
                                    {
                                        name: 'Long curves',
                                        model: 'Long curves'
                                    },
                                elem3:
                                    {
                                        name: 'Radical',
                                        model: 'Radical'
                                    },
                                elem4:
                                    {
                                        name: 'Pro',
                                        model: 'Pro'
                                    }
                            },
                        fixation:
                            {
                                name: 'Fixation',
                                elem1:
                                    {
                                        name: 'Single',
                                        model: 'Single'
                                    },
                                elem2:
                                    {
                                        name: 'Twin',
                                        model: 'Twin'
                                    },
                                elem3:
                                    {
                                        name: 'Thruster',
                                        model: 'Thruster'
                                    },
                                elem4:
                                    {
                                        name: 'Quad',
                                        model: 'Quad'
                                    },
                                elem5:
                                    {
                                        name: 'Five',
                                        model: 'Five'
                                    }
                            },
                        vague:
                            {
                                name: 'Wave',
                                elem1:
                                    {
                                        name: '0.5m - 1.5m | 1ft - 4ft flat',
                                        model: '0.5m - 1.5m | 1ft - 4ft flat'
                                    },
                                elem2:
                                    {
                                        name: '0.5m - 1.5m | 1ft - 4ft hollow',
                                        model: '0.5m - 1.5m | 1ft - 4ft hollow'
                                    },
                                elem3:
                                    {
                                        name: '>1.5m | >4ft flat',
                                        model: '>1.5m | >4ft flat'
                                    },
                                elem4:
                                    {
                                        name: '>1.5m | >4ft hollow',
                                        model: '>1.5m | >4ft hollow'
                                    }
                            }
                    },
                textloading: '<div class="splashcont"><i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw splashimg"></i><span class="sr-only">Loading...</span>' + '<h1>Designer loading...</h1><p>There we will establish the best FYN™ configuration for your surf.</p>' + '<h5>Loading, this can take some time depand of your speed connection.</h5>'
            };
    }

    vm.modalcart = false;

    vm.addItemCart = function(selectedfinsingle, selectedfintwin, selectedfinthruster){
        var product;

        if (selectedfinsingle && selectedfinsingle.idproduit) {
            product = _.find($scope.products, 'id', selectedfinsingle.idproduit);
            product.option = selectedfinsingle.plug;
            CartService.addItem(product, $rootScope.prices);
        }
        if (selectedfintwin && selectedfintwin.idproduit) {
            product = _.find($scope.$parent.products, 'id', selectedfintwin.idproduit);
            product.option = selectedfintwin.plug;
            CartService.addItem(product, $rootScope.prices, 2);
        }
        if (selectedfinthruster && selectedfinthruster.idproduit) {
            product = _.find($scope.$parent.products, 'id', selectedfinthruster.idproduit);
            product.option = selectedfinthruster.plug;
            CartService.addItem(product, $rootScope.prices, 2);
        }
    };

    vm.priceSlider = 150;

    //dev mode URL du repertoire de fichiers
    vm.devmode = 'https://d4ka46eh5rb9g.cloudfront.net/';
    //vm.devmode = '//localhost:9000/';

    vm.firstarrive = true;
    vm.validcart = false;
    vm.infoStep = 0;
    vm.infoStepNb = 8;
    vm.helpmode = false;
    vm.finready = false;
    vm.boardready = false;
    vm.elemSelected = [];
    vm.tabstuff = 0;
    vm.facteurposition = 0;
    vm.nombconfselec = 0;
    vm.ratiospeedstyle = '0%';
    vm.posy = '';
    vm.showinfos = true;

    //color shaperoom
    vm.bleuclair = new BABYLON.Color4(0.096, 0.744, 0.58, 1);
    vm.bleufonce = new BABYLON.Color4(0.096, 0.496, 0.872, 1);
    vm.gris = new BABYLON.Color4(0.316, 0.356, 0.404, 1);

    vm.roomcolor = vm.gris;

    vm.singleallreadyselected = false;
    vm.twinallreadyselected = false;
    vm.thrusterallreadyselected = false;
    vm.finselecpositionsingle = 0;
    vm.finselecpositiontwin = 0;
    vm.finselecpositionthruster = 0;

    vm.positionsinglefin = 100;
    vm.positiontwinfin = 90;
    vm.positionthrusterfin = 80;
    vm.positionsurfer = 50;
    vm.finselecposition = 0;

    vm.configfixations = '';
    vm.configpoids = 0;
    vm.configniveau = 0;
    vm.configvague = 0;


    vm.singleValue = 'fin1';
    vm.twinValue = '';
    vm.thrusterValue = '';
    vm.sliderFinSinglevalue=100;
    vm.sliderFinTwinvalue=90;
    vm.sliderFinThrustervalue=80;

    vm.selectedcamera = 'camera1';

    // db elements et url objs
    vm.modelsObj = [
        {
            id: 0,
            type: 'boards',
            name: vm.traduction.boards.name,
            elements: [
                {
                    type: 'board',
                    selected: false,
                    name: 'board1',
                    description: vm.traduction.boards.board1.description,
                    ico: '/assets/fyn/icons/boards/shortboard.svg',
                    icoselect: '/assets/fyn/icons/boards/shortboard-selected.svg',
                    model: vm.traduction.boards.board1.model,
                    url: '3dmodels/boards/shortboard/',
                    obj: 'shortboard.stl',
                    scale: 1,
                    facteursize: 12,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0
                },
                {
                    type: 'board',
                    selected: false,
                    name: 'board2',
                    description: vm.traduction.boards.board2.description,
                    ico: '/assets/fyn/icons/boards/fish.svg',
                    icoselect: '/assets/fyn/icons/boards/fish-selected.svg',
                    model: vm.traduction.boards.board2.model,
                    url: '3dmodels/boards/fish/',
                    obj: 'fish.stl',
                    scale: 1,
                    facteursize: 10,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0
                },
                {
                    type: 'board',
                    selected: false,
                    name: 'board3',
                    description: vm.traduction.boards.board3.description,
                    ico: '/assets/fyn/icons/boards/retro.svg',
                    icoselect: '/assets/fyn/icons/boards/retro-selected.svg',
                    model: vm.traduction.boards.board3.model,
                    url: '3dmodels/boards/retro/',
                    obj: 'retro.stl',
                    scale: 1,
                    facteursize: 11,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0
                },
                {
                    type: 'board',
                    selected: false,
                    name: 'board4',
                    description:  vm.traduction.boards.board4.description,
                    ico: '/assets/fyn/icons/boards/minimalibu.svg',
                    icoselect: '/assets/fyn/icons/boards/minimalibu-selected.svg',
                    model: vm.traduction.boards.board4.model,
                    url: '3dmodels/boards/minimalibu/',
                    obj: 'minimalibu.stl',
                    scale: 1,
                    facteursize: 20,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0
                },
                {
                    type: 'board',
                    selected: false,
                    name: 'board5',
                    description: vm.traduction.boards.board5.description,
                    ico: '/assets/fyn/icons/boards/longboard.svg',
                    icoselect: '/assets/fyn/icons/boards/longboard-selected.svg',
                    model: vm.traduction.boards.board5.model,
                    url: '3dmodels/boards/longboard/',
                    obj: 'longboard.stl',
                    scale: 1,
                    facteursize: 30,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0
                }
            ]
        },
        {
            id: 1,
            type: 'fins',
            name: vm.traduction.fins.name,
            elements: [
                {
                    type: 'fin',
                    single: false,
                    twin: false,
                    thruster: false,
                    name: 'fin1',
                    description: '',
                    model: vm.traduction.fins.fin1.model,
                    url: '3dmodels/fins/genome-classique2/',
                    obj: 'genome-classique.babylon',
                    facteurflex: 0.3,
                    scalex: 1,
                    scaley: 1,
                    scalez: 1,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0,
                    color: 'white',
                    idproduit: 'classic2'
                },
                {
                    type: 'fin',
                    single: false,
                    twin: false,
                    thruster: false,
                    name: 'fin2',
                    description: '',
                    model: vm.traduction.fins.fin2.model,
                    url: '3dmodels/fins/genome-classique2/',
                    obj: 'genome-classique.babylon',
                    facteurflex: 0.4,
                    scalex: 1,
                    scaley: 1,
                    scalez: 1,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0,
                    color: 'black',
                    idproduit: 'pro2'
                },
                {
                    type: 'fin',
                    single: false,
                    twin: false,
                    thruster: false,
                    name: 'fin3',
                    description: '',
                    model: vm.traduction.fins.fin3.model,
                    url: '3dmodels/fins/genome-longboard/',
                    obj: 'genome-longboard.babylon',
                    facteurflex: 0.5,
                    scalex: 1,
                    scaley: 1,
                    scalez: 1,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0,
                    color: 'white',
                    idproduit: 'longboard'
                },
                {
                    type: 'fin',
                    single: false,
                    twin: false,
                    thruster: false,
                    name: 'fin4',
                    description: '',
                    model: vm.traduction.fins.fin4.model,
                    url: '3dmodels/fins/classique/',
                    obj: 'classique.babylon',
                    facteurflex: 0.9,
                    scalex: 1,
                    scaley: 1,
                    scalez: 1,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0,
                    color: 'classique',
                    idproduit: false
                },
                {
                    type: 'fin',
                    single: false,
                    twin: false,
                    thruster: false,
                    name: 'fin5',
                    description: '',
                    model: vm.traduction.fins.fin5.model,
                    url: '3dmodels/fins/longboard-classique/',
                    obj: 'longboard-classique.babylon',
                    facteurflex: 1,
                    scalex: 1,
                    scaley: 1,
                    scalez: 1,
                    positionx: 0,
                    positiony: 0,
                    positionz: 0,
                    rotationx: 0,
                    rotationy: 0,
                    rotationz: 0,
                    color: 'classiquelong',
                    idproduit: false
                }
            ]
        },
        {
            id: 2,
            type: 'surfeur',
            name: vm.traduction.surfeur.name,
            elements: [
                {
                    type: 'poids',
                    name: vm.traduction.surfeur.poids.name,
                    elements: [
                        {
                            type: 'poids',
                            selected: true,
                            name: vm.traduction.surfeur.poids.elem1.name,
                            description: '',
                            ico: '/assets/fyn/icons/poids/ultraleger.svg',
                            icoselect: '/assets/fyn/icons/poids/ultraleger-selected.svg',
                            model: vm.traduction.surfeur.poids.elem1.model,
                            facteur: 0
                        },
                        {
                            type: 'poids',
                            selected: false,
                            name: vm.traduction.surfeur.poids.elem2.name,
                            description: '',
                            ico: '/assets/fyn/icons/poids/leger.svg',
                            icoselect: '/assets/fyn/icons/poids/leger-selected.svg',
                            model: vm.traduction.surfeur.poids.elem2.model,
                            facteur: 1
                        },
                        {
                            type: 'poids',
                            selected: false,
                            name: vm.traduction.surfeur.poids.elem3.name,
                            description: '',
                            ico: '/assets/fyn/icons/poids/moyen.svg',
                            icoselect: '/assets/fyn/icons/poids/moyen-selected.svg',
                            model: vm.traduction.surfeur.poids.elem3.model,
                            facteur: 2
                        },
                        {
                            type: 'poids',
                            selected: false,
                            name: vm.traduction.surfeur.poids.elem4.name,
                            description: '',
                            ico: '/assets/fyn/icons/poids/lourd.svg',
                            icoselect: '/assets/fyn/icons/poids/lourd-selected.svg',
                            model: vm.traduction.surfeur.poids.elem4.model,
                            facteur: 3
                        }
                    ]
                },
                {
                    type: 'styles',
                    name: vm.traduction.surfeur.style.name,
                    elements: [
                        {
                            type: 'style',
                            selected: true,
                            name: vm.traduction.surfeur.style.elem1.name,
                            description: '',
                            ico: '/assets/fyn/icons/levels/debutant.svg',
                            icoselect: '/assets/fyn/icons/levels/debutant-selected.svg',
                            model: vm.traduction.surfeur.style.elem1.model,
                            facteur: 0
                        },
                        {
                            type: 'style',
                            selected: false,
                            name: vm.traduction.surfeur.style.elem2.name,
                            description: '',
                            ico: '/assets/fyn/icons/levels/longue.svg',
                            icoselect: '/assets/fyn/icons/levels/longue-selected.svg',
                            model: vm.traduction.surfeur.style.elem2.model,
                            facteur: 1
                        },
                        {
                            type: 'style',
                            selected: false,
                            name: vm.traduction.surfeur.style.elem3.name,
                            description: '',
                            ico: '/assets/fyn/icons/levels/radical.svg',
                            icoselect: '/assets/fyn/icons/levels/radical-selected.svg',
                            model: vm.traduction.surfeur.style.elem3.model,
                            facteur: 2
                        },
                        {
                            type: 'style',
                            selected: false,
                            name: vm.traduction.surfeur.style.elem4.name,
                            description: '',
                            ico: '/assets/fyn/icons/levels/pro.svg',
                            icoselect: '/assets/fyn/icons/levels/pro-selected.svg',
                            model: vm.traduction.surfeur.style.elem4.model,
                            facteur: 3
                        }
                    ]
                },
                {
                    type: 'fixations',
                    name: vm.traduction.surfeur.fixation.name,
                    elements: [
                        {
                            type: 'fixation',
                            selected: false,
                            name: vm.traduction.surfeur.fixation.elem1.name,
                            description: '',
                            ico: '/assets/fyn/icons/fixations/single.svg',
                            icoselect: '/assets/fyn/icons/fixations/single-selected.svg',
                            model: vm.traduction.surfeur.fixation.elem1.model
                        },
                        {
                            type: 'fixation',
                            selected: false,
                            name: vm.traduction.surfeur.fixation.elem2.name,
                            description: '',
                            ico: '/assets/fyn/icons/fixations/twin.svg',
                            icoselect: '/assets/fyn/icons/fixations/twin-selected.svg',
                            model: vm.traduction.surfeur.fixation.elem2.model
                        },
                        {
                            type: 'fixation',
                            selected: false,
                            name: vm.traduction.surfeur.fixation.elem3.name,
                            description: '',
                            ico: '/assets/fyn/icons/fixations/thruster.svg',
                            icoselect: '/assets/fyn/icons/fixations/thruster-selected.svg',
                            model: vm.traduction.surfeur.fixation.elem3.model
                        },
                        {
                            type: 'fixation',
                            selected: false,
                            name: vm.traduction.surfeur.fixation.elem4.name,
                            description: '',
                            ico: '/assets/fyn/icons/fixations/quad.svg',
                            icoselect: '/assets/fyn/icons/fixations/quad-selected.svg',
                            model: vm.traduction.surfeur.fixation.elem4.model
                        },
                        {
                            type: 'fixation',
                            selected: false,
                            name: vm.traduction.surfeur.fixation.elem5.name,
                            description: '',
                            ico: '/assets/fyn/icons/fixations/five.svg',
                            icoselect: '/assets/fyn/icons/fixations/five-selected.svg',
                            model: vm.traduction.surfeur.fixation.elem5.model
                        }
                    ]
                },
                {
                    type: 'vagues',
                    name: 'Vague',
                    elements:[
                        {
                            type: 'vague',
                            selected: true,
                            name: vm.traduction.surfeur.vague.elem1.name,
                            description: '',
                            ico: '/assets/fyn/icons/vagues/petitplat.svg',
                            icoselect: '/assets/fyn/icons/vagues/petitplat-selected.svg',
                            model: vm.traduction.surfeur.vague.elem1.model,
                            facteur: 0
                        },
                        {
                            type: 'vague',
                            selected: false,
                            name: vm.traduction.surfeur.vague.elem2.name,
                            description: '',
                            ico: '/assets/fyn/icons/vagues/petitcreux.svg',
                            icoselect: '/assets/fyn/icons/vagues/petitcreux-selected.svg',
                            model: vm.traduction.surfeur.vague.elem2.model,
                            facteur: 1
                        },
                        {
                            type: 'vague',
                            selected: false,
                            name: vm.traduction.surfeur.vague.elem3.name,
                            description: '',
                            ico: '/assets/fyn/icons/vagues/grosplat.svg',
                            icoselect: '/assets/fyn/icons/vagues/grosplat-selected.svg',
                            model: vm.traduction.surfeur.vague.elem3.model,
                            facteur: 2
                        },
                        {
                            type: 'vague',
                            selected: false,
                            name: vm.traduction.surfeur.vague.elem4.name,
                            description: '',
                            ico: '/assets/fyn/icons/vagues/groscreux.svg',
                            icoselect: '/assets/fyn/icons/vagues/groscreux-selected.svg',
                            model: vm.traduction.surfeur.vague.elem4.model,
                            facteur: 3
                        }
                    ]
                }
            ]
        }
    ];

    //tabs des configs
    vm.configs = [
        {
            name: 'single',
            number: 1,
            centrage: 0,
            position: 1,
            positioninit: 100
        },
        {
            name: 'twin',
            number: 2,
            centrage: 0.65,
            position: 1,
            positioninit: 90
        },
        {
            name: 'thruster',
            number: 2,
            centrage: 0.9,
            position: 1,
            positioninit: 80
        }
    ];

    // get canvas DOM element
    vm.canvasMain = document.getElementById('renderCanvasMain');

    //creation de la scene et chargeent de la db modelsObj
    vm.launchsceneMain = function () {
        // load the 3D engine
        vm.engine = new BABYLON.Engine(vm.canvasMain, true);

        vm.engine.loadingUIText = vm.traduction.textloading;

        // createScene function that creates and return the scene
        var createScene = function () {
            // create a basic BJS Scene object
            vm.scene = new BABYLON.Scene(vm.engine);

            vm.scene.clearColor = vm.roomcolor;

            vm.camera1 = new BABYLON.ArcRotateCamera("camera1", 0, 0, 0, BABYLON.Vector3.Zero(), vm.scene);
            vm.camera1.setPosition(new BABYLON.Vector3(-1000, 0, 0));
            vm.camera1.attachControl(vm.canvasMain, true);

            vm.camera2 = new BABYLON.ArcRotateCamera("camera2", 0, 0, 0, BABYLON.Vector3.Zero(), vm.scene);
            vm.camera2.setPosition(new BABYLON.Vector3(-2000, 0, 0));
            vm.camera2.attachControl(vm.canvasMain, true);

            vm.light0 = new BABYLON.HemisphericLight("Hemi0", new BABYLON.Vector3(0, 1, 0), vm.scene);
            vm.light0.diffuse = new BABYLON.Color3(1, 1, 1);
            vm.light0.specular = new BABYLON.Color3(1, 1, 1);
            vm.light0.groundColor = new BABYLON.Color3(0.8, 0.8, 0.8);

            vm.boardmaterial = new BABYLON.StandardMaterial("board", vm.scene);

            vm.boardmaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);//white

            vm.fixedmaterial = new BABYLON.StandardMaterial("fixed", vm.scene);

            vm.fixedmaterial.diffuseColor = vm.bleuclair;//white

            vm.movedmaterial = new BABYLON.StandardMaterial("moved", vm.scene);

            vm.movedmaterial.diffuseColor = vm.bleufonce;//white

            vm.wrongmaterial = new BABYLON.StandardMaterial("wrong", vm.scene);

            vm.wrongmaterial.diffuseColor = vm.gris;//white

            vm.materialFinBlanc = new BABYLON.StandardMaterial("finblanc", vm.scene);
            vm.materialFinBlanc.diffuseTexture = new BABYLON.Texture(vm.devmode + '3dmodels/fins/genome-classique2/fin10000.png', vm.scene);

            vm.materialFinBlack = new BABYLON.StandardMaterial("finblack", vm.scene);
            vm.materialFinBlack.diffuseTexture = new BABYLON.Texture(vm.devmode + '3dmodels/fins/genome-pro/fin10000.PNG', vm.scene);

            vm.materialFinClassic = new BABYLON.StandardMaterial("finclassic", vm.scene);
            vm.materialFinClassic.diffuseTexture = new BABYLON.Texture(vm.devmode + '3dmodels/fins/classique/classique Material - Texture par défaut.PNG', vm.scene);

            vm.materialFinClassicLongboard = new BABYLON.StandardMaterial("finclassiclongboard", vm.scene);
            vm.materialFinClassicLongboard.diffuseTexture = new BABYLON.Texture(vm.devmode + '3dmodels/fins/longboard-classique/longboard (2) Material - Texture par défaut.PNG', vm.scene);

            // show axis
            var showAxis = function(size) {
                var makeTextPlane = function(text, color, size) {
                    var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, vm.scene, true);
                    dynamicTexture.hasAlpha = true;
                    dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color , "transparent", true);
                    var plane = new BABYLON.Mesh.CreatePlane("TextPlane", size, vm.scene, true);
                    plane.material = new BABYLON.StandardMaterial("TextPlaneMaterial", vm.scene);
                    plane.material.backFaceCulling = false;
                    plane.material.specularColor = new BABYLON.Color3(0, 0, 0);
                    plane.material.diffuseTexture = dynamicTexture;
                    return plane;
                };

                var axisX = BABYLON.Mesh.CreateLines("axisX", [
                    new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
                    new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
                ], vm.scene);
                axisX.color = new BABYLON.Color3(1, 0, 0);
                var xChar = makeTextPlane("X", "red", size / 10);
                xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0);
                var axisY = BABYLON.Mesh.CreateLines("axisY", [
                    new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( -0.05 * size, size * 0.95, 0),
                    new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( 0.05 * size, size * 0.95, 0)
                ], vm.scene);
                axisY.color = new BABYLON.Color3(0, 1, 0);
                var yChar = makeTextPlane("Y", "green", size / 10);
                yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size);
                var axisZ = BABYLON.Mesh.CreateLines("axisZ", [
                    new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0 , -0.05 * size, size * 0.95),
                    new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0, 0.05 * size, size * 0.95)
                ], vm.scene);
                axisZ.color = new BABYLON.Color3(0, 0, 1);
                var zChar = makeTextPlane("Z", "blue", size / 10);
                zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size);
            };

            //showAxis(50);

            vm.postProcess = new BABYLON.FxaaPostProcess("fxaa", 1.0, vm[vm.selectedcamera]);

            // return the created scene
            return vm.scene;
        };

        // call the createScene function
        vm.scene = createScene();

        // run the render loop
        vm.engine.runRenderLoop(function () {
            vm.scene.render();
        });

        // the canvas/window resize event handler
        window.addEventListener('resize', function () {
            vm.engine.resize();
        });

        vm.loader = new BABYLON.AssetsManager(vm.scene);

        BABYLON.OBJFileLoader.OPTIMIZE_WITH_UV = true;

        vm.modelsObj.forEach(function(obj){
            if(obj.type != 'surfeur'){
                //creation des mesh pour chaque config
                if(obj.type === 'fins'){
                    vm.configs.forEach(function(config){
                        obj.elements.forEach(function(elem){
                            var elemname = elem.name + config.name;
                            var chargeobj = vm.loader.addMeshTask(elemname, "", vm.devmode + elem.url, elem.obj);
                            chargeobj.onSuccess = function(t){
                                t.loadedMeshes.forEach(function(m) {
                                    m.id = elemname + 'Child';
                                    m.name = elemname + 'Child';
                                    m.position.x = elem.positionx;
                                    m.position.y = elem.positiony;
                                    m.position.z = elem.positionz;
                                    m.rotation.x = elem.rotationx;
                                    m.rotation.y = elem.rotationy;
                                    m.rotation.z = elem.rotationz;
                                    m.scaling.x = elem.scalex;
                                    m.scaling.y = elem.scaley;
                                    m.scaling.z = elem.scalez;
                                    m.donnees = elem.donnees;
                                    m.facteurflex = elem.facteurflex;
                                    m.setEnabled(false);

                                    if(elem.color === 'white'){
                                        m.material = vm.materialFinBlanc;
                                    }
                                    if(elem.color === 'black'){
                                        m.material = vm.materialFinBlack;
                                    }
                                    if(elem.color === 'classiquelong'){
                                        m.material = vm.materialFinClassicLongboard;
                                    }
                                    if(elem.color === 'classique'){
                                        m.material = vm.materialFinClassic;
                                    }

                                    //debut analyse fin
                                    var finfacetnosename = elemname + 'nose';
                                    var finfacettailname = elemname + 'tail';
                                    var finfacetnoseindex = elemname + 'noseindex';
                                    var finfacettailindex = elemname + 'tailindex';

                                    //modelisations des points
                                    var finnosepoint = elemname + 'nosepoint';
                                    var fintailpoint = elemname + 'tailpoint';
                                    var fincenterpoint = elemname + 'centerpoint';

                                    m.updateFacetData();
                                    m._facetPositions.forEach(function(facetPos, index) {
                                        if (index === 0){
                                            vm[finfacetnosename] = facetPos;
                                            vm[finfacettailname] = facetPos;
                                            vm[finfacetnoseindex] = index;
                                            vm[finfacettailindex] = index;
                                        }else{
                                            if (vm[finfacetnosename].x > facetPos.x){
                                                vm[finfacetnosename] = facetPos;
                                                vm[finfacetnoseindex] = index;
                                            }
                                            if (vm[finfacettailname].x < facetPos.x){
                                                vm[finfacettailname] = facetPos;
                                                vm[finfacettailindex] = index;
                                            }
                                        }
                                    });

                                    vm[finfacetnosename] = m.getFacetLocalPositions();
                                    vm[finfacettailname] = m.getFacetLocalPositions();

                                    //point du nose
                                    vm[finnosepoint] = BABYLON.Mesh.CreateDisc(finnosepoint, 5, 100, vm.scene);
                                    vm[finnosepoint].parent = m;
                                    vm[finnosepoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                    vm[finnosepoint].position = vm[finfacetnosename][vm[finfacetnoseindex]];
                                    vm[finnosepoint].scaling.x = 5;
                                    vm[finnosepoint].scaling.y = 5;
                                    vm[finnosepoint].scaling.z = 5;
                                    vm[finnosepoint].isVisible = false;

                                    //point du tail
                                    vm[fintailpoint] = BABYLON.Mesh.CreateDisc(fintailpoint, 5, 100, vm.scene);
                                    vm[fintailpoint].parent = m;
                                    vm[fintailpoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                    vm[fintailpoint].position = vm[finfacettailname][vm[finfacettailindex]];
                                    vm[fintailpoint].scaling.x = 5;
                                    vm[fintailpoint].scaling.y = 5;
                                    vm[fintailpoint].scaling.z = 5;
                                    vm[fintailpoint].isVisible = false;

                                    //recentrage du point fintail
                                    vm[fintailpoint].position.z = vm[finnosepoint].position.z;

                                    //point du centre d'aileron
                                    vm[fincenterpoint] = BABYLON.Mesh.CreateDisc(fincenterpoint, 5, 100, vm.scene);
                                    vm[fincenterpoint].parent = m;
                                    vm[fincenterpoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                    vm[fincenterpoint].position.x = vm[finnosepoint].position.x + ( (vm[fintailpoint].position.x - vm[finnosepoint].position.x) / 2 );
                                    vm[fincenterpoint].position.y = vm[fintailpoint].position.y;
                                    vm[fincenterpoint].position.z = vm[fintailpoint].position.z;
                                    vm[fincenterpoint].scaling.x = 5;
                                    vm[fincenterpoint].scaling.y = 5;
                                    vm[fincenterpoint].scaling.z = 5;
                                    vm[fincenterpoint].isVisible = false;

                                    vm.finready = true;
                                    //fin modelisation points

                                    //recentrage de la fin dans le world sur le point de contact et sur le tail
                                    m.position.x = vm[fintailpoint].position.x - (vm[fintailpoint].position.x * 2);
                                    m.position.y = vm[finnosepoint].position.y - (vm[finnosepoint].position.y * 2);
                                    m.position.z = vm[finnosepoint].position.z - (vm[finnosepoint].position.z * 2);

                                    //m.position.z = m.position.z + (100 * config.centrage);


                                    //create parent avec name de l'obj
                                    var parentname = elemname;

                                    vm[parentname] = BABYLON.Mesh.CreateSphere(parentname, 0, 0, vm.scene);
                                    vm[parentname].id = parentname;
                                    vm[parentname].isVisible = false;
                                    vm[parentname].position = new BABYLON.Vector3.Zero();

                                    //facteurtotal correspond au facteur des ailerons avec flex et configuration (0 etant le flex max et 1 le flex min)
                                    vm[parentname].facteurtotal = (m.facteurflex + config.position)/2;
                                    vm[parentname].typeconfig = config.name;
                                    vm[parentname].facteurcentrage = config.centrage;

                                    m.parent = vm[parentname];

                                    if(config.number > 1) {
                                        vm.nameinstance = m.name + config.number;
                                        vm[vm.nameinstance] = m.createInstance(vm.nameinstance);
                                        vm[vm.nameinstance].parent = m.parent;
                                        vm[vm.nameinstance].setEnabled(elem[config.name]);
                                        vm[vm.nameinstance].position = m.position;
                                        vm[vm.nameinstance].id = vm.nameinstance;
                                        vm[vm.nameinstance].name = vm.nameinstance;
                                    }

                                });
                            }
                        });
                    });
                }else{
                    obj.elements.forEach(function(elem){
                        var chargeobj = vm.loader.addMeshTask(elem.name, "", vm.devmode + elem.url, elem.obj);
                        chargeobj.onSuccess = function(t){
                            t.loadedMeshes.forEach(function(m) {
                                m.id = elem.name + 'Child';
                                m.name = elem.name + 'Child';
                                m.position.x = elem.positionx;
                                m.position.y = elem.positiony;
                                m.position.z = elem.positionz;
                                m.rotation.x = elem.rotationx;
                                m.rotation.y = elem.rotationy;
                                m.rotation.z = elem.rotationz;
                                m.scaling.x = elem.scale;
                                m.scaling.y = elem.scale;
                                m.scaling.z = elem.scale;
                                m.setEnabled(false);

                                //debut analyse board
                                if(elem.selected === true){
                                    vm.boardselec = elem.name;
                                }

                                //declaration tableau des positions de fin
                                var posfintabnamey = elem.name + 'posfintaby';
                                vm[posfintabnamey] = [];
                                var posfintabnametempy = elem.name + 'posfintabtempy';
                                vm[posfintabnametempy] = [];

                                var posfintabnamez = elem.name + 'posfintabz';
                                vm[posfintabnamez] = [];
                                var posfintabnametempz = elem.name + 'posfintabtempz';
                                vm[posfintabnametempz] = [];

                                var facetnosename = elem.name + 'nose';
                                var facettailname = elem.name + 'tail';
                                var facetwidelname = elem.name + 'widel';
                                var facetwidername = elem.name + 'wider';
                                var facetheightname = elem.name + 'height';
                                var facetnoseindex = elem.name + 'noseindex';
                                var facettailindex = elem.name + 'tailindex';
                                var facetwidelindex = elem.name + 'widelindex';
                                var facetwiderindex = elem.name + 'widerindex';
                                var facetheightindex = elem.name + 'heightindex';

                                //modelisations des points
                                var nosepoint = elem.name + 'nosepoint';
                                var tailpoint = elem.name + 'tailpoint';
                                var widelpoint = elem.name + 'widelpoint';
                                var widerpoint = elem.name + 'widerpoint';
                                var centerbotpoint = elem.name + 'centerbotpoint';

                                m.updateFacetData();
                                m.partitioningSubdivisions = 20;
                                m.partitioningBBoxRatio = 0.99;
                                m._facetPositions.forEach(function(facetPos, index) {
                                        if (index === 0){
                                            vm[facetnosename] = facetPos;
                                            vm[facettailname] = facetPos;
                                            vm[facetwidelname] = facetPos;
                                            vm[facetwidername] = facetPos;
                                            vm[facetheightname] = facetPos;
                                            vm[facetnoseindex] = index;
                                            vm[facettailindex] = index;
                                            vm[facetwidelindex] = index;
                                            vm[facetwiderindex] = index;
                                            vm[facetheightindex] = index;
                                        }else{
                                            if (vm[facetnosename].x > facetPos.x){
                                                vm[facetnosename] = facetPos;
                                                vm[facetnoseindex] = index;
                                            }
                                            if (vm[facettailname].x < facetPos.x){
                                                vm[facettailname] = facetPos;
                                                vm[facettailindex] = index;
                                            }
                                            if (vm[facetwidelname].z < facetPos.z){
                                                vm[facetwidelname] = facetPos;
                                                vm[facetwidelindex] = index;
                                            }
                                            if (vm[facetwidername].z > facetPos.z){
                                                vm[facetwidername] = facetPos;
                                                vm[facetwiderindex] = index;
                                            }
                                            if (vm[facetheightname].y < facetPos.y){
                                                vm[facetheightname] = facetPos;
                                                vm[facetheightindex] = index;
                                            }
                                        }
                                    });

                                //calcul des elements pour positionner les fins
                                var centreboard = vm[facetnosename].x + ( (vm[facettailname].x - vm[facetnosename].x) / 2 );
                                var tailboard = vm[facettailname].x;
                                var distancetailcentre = Math.abs(tailboard - centreboard);

                                //boucle avec precision pour le nombre de decoupes pour le placement des fins

                                var indexposy = 0;
                                var indexposz = 0;
                                for (var i = 0; i <= 100; i++) {
                                    if(i == 5 || i == 10 || i == 15 || i == 20 || i == 25 || i == 30 || i == 35 || i == 40 || i == 45 || i == 50 || i == 55 || i == 60 || i == 65 || i == 70 || i == 75 || i == 77 || i == 80 || i == 83 || i == 85 || i == 87 || i == 90 || i == 93 || i == 95 || i == 97 || i == 100){
                                        var step = i/100;
                                        var position = centreboard + (distancetailcentre * step);
                                        var positionyname = 'positiony' + i;
                                        var positionzname = 'positionz' + i;
                                        m._facetPositions.forEach(function(facetPos, index) {
                                            if (index === 0){
                                                vm[positionyname] = facetPos;
                                                indexposy = index;
                                                vm[positionzname] = facetPos;
                                                indexposz = index;
                                            }else{
                                                if (vm[positionyname].y <= facetPos.y && facetPos.x >= position){
                                                    vm[positionyname] = facetPos;
                                                    indexposy = index;
                                                }
                                                if (vm[positionzname].z <= facetPos.z && facetPos.x >= position){
                                                    vm[positionzname] = facetPos;
                                                    indexposz = index;
                                                }
                                            }
                                        });
                                        //position y
                                        vm[positionyname] = [];
                                        vm[positionyname].position = indexposy;
                                        vm[positionyname].pourcpos = i;
                                        //position z
                                        vm[positionzname] = [];
                                        vm[positionzname].position = indexposz;
                                        vm[positionzname].pourcpos = i;

                                        //push dans le tableau des positions liées a la board
                                        vm[posfintabnametempy].push(vm[positionyname]);
                                        vm[posfintabnametempz].push(vm[positionzname]);
                                    }
                                }

                                vm[facetnosename] = m.getFacetPosition(vm[facetnoseindex]);
                                vm[facettailname] = m.getFacetPosition(vm[facettailindex]);
                                vm[facetwidelname] = m.getFacetPosition(vm[facetwidelindex]);
                                vm[facetwidername] = m.getFacetPosition(vm[facetwiderindex]);
                                vm[facetheightname] = m.getFacetPosition(vm[facetheightindex]);

                                //point du nose
                                vm[nosepoint] = BABYLON.Mesh.CreateDisc(nosepoint, 5, 100, vm.scene);
                                vm[nosepoint].parent = m;
                                vm[nosepoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                vm[nosepoint].position = vm[facetnosename];
                                vm[nosepoint].scaling.x = 5;
                                vm[nosepoint].scaling.y = 5;
                                vm[nosepoint].scaling.z = 5;
                                vm[nosepoint].isVisible = false;

                                //point du tail
                                vm[tailpoint] = BABYLON.Mesh.CreateDisc(tailpoint, 5, 100, vm.scene);
                                vm[tailpoint].parent = m;
                                vm[tailpoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                vm[tailpoint].position = vm[facettailname];
                                vm[tailpoint].scaling.x = 5;
                                vm[tailpoint].scaling.y = 5;
                                vm[tailpoint].scaling.z = 5;
                                vm[tailpoint].isVisible = false;

                                //point le plus large gauche
                                vm[widelpoint] = BABYLON.Mesh.CreateDisc(widelpoint, 5, 100, vm.scene);
                                vm[widelpoint].parent = m;
                                vm[widelpoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                vm[widelpoint].position = vm[facetwidelname];
                                vm[widelpoint].scaling.x = 5;
                                vm[widelpoint].scaling.y = 5;
                                vm[widelpoint].scaling.z = 5;
                                vm[widelpoint].isVisible = false;

                                //point le plus large droit
                                vm[widerpoint] = BABYLON.Mesh.CreateDisc(widerpoint, 5, 100, vm.scene);
                                vm[widerpoint].parent = m;
                                vm[widerpoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                vm[widerpoint].position = vm[facetwidername];
                                vm[widerpoint].scaling.x = 5;
                                vm[widerpoint].scaling.y = 5;
                                vm[widerpoint].scaling.z = 5;
                                vm[widerpoint].isVisible = false;

                                //point du centre de la planche
                                vm[centerbotpoint] = BABYLON.Mesh.CreateDisc(centerbotpoint, 5, 100, vm.scene);
                                vm[centerbotpoint].parent = m;
                                vm[centerbotpoint].diffuseColor = new BABYLON.Color4(1, 0, 0, 1);
                                vm[centerbotpoint].position.x = vm[facetnosename].x + ( (vm[facettailname].x - vm[facetnosename].x) / 2 );
                                vm[centerbotpoint].position.y = vm[facetheightname].y;
                                vm[centerbotpoint].position.z = vm[facetwidername].z + ( (vm[facetwidelname].z - vm[facetwidername].z) / 2 );
                                vm[centerbotpoint].scaling.x = 5;
                                vm[centerbotpoint].scaling.y = 5;
                                vm[centerbotpoint].scaling.z = 5;
                                vm[centerbotpoint].isVisible = false;

                                //recentrage du tail sur
                                vm[tailpoint].position.z = vm[centerbotpoint].position.z;

                                vm.boardready = true;
                                //fin modelisation points

                                //recentrage de la planche dans le word sur son center point
                                m.position.x = vm[centerbotpoint].position.x - (vm[centerbotpoint].position.x * 2);
                                m.position.y = vm[centerbotpoint].position.y - (vm[centerbotpoint].position.y * 2);
                                m.position.z = vm[centerbotpoint].position.z - (vm[centerbotpoint].position.z * 2);
                                //fin analyse board


                                //create parent avec name de l'obj
                                var parentname = elem.name;
                                vm[parentname] = BABYLON.Mesh.CreateSphere(parentname, 0, 0, vm.scene);
                                vm[parentname].id = parentname;
                                vm[parentname].isVisible = false;
                                vm[parentname].position = new BABYLON.Vector3.Zero();
                                m.facteursize = elem.facteursize;
                                m.parent = vm[parentname];
                                m.material = vm.boardmaterial;

                                if(elem.selected === true){
                                    vm.selecElem(elem.name);
                                }

                                vm[posfintabnametempy].forEach(function(pos){
                                    pos.position = m.getFacetPosition(pos.position);
                                });
                                vm[posfintabnametempz].forEach(function(pos){
                                    pos.position = m.getFacetPosition(pos.position);
                                });

                                vm[posfintabnamey] = vm[posfintabnametempy];
                                vm[posfintabnamez] = vm[posfintabnametempz];

                            });
                        }
                    });
                }
            }
        });

        vm.surferpoint = BABYLON.Mesh.CreateDisc("surferpoint", 1, 100, vm.scene);
        vm.surferpoint.diffuseColor = BABYLON.Color3.Blue();
        vm.surferpoint.position = new BABYLON.Vector3(0, 9, 0);
        vm.surferpoint.scaling.x = 55;
        vm.surferpoint.scaling.y = 55;
        vm.surferpoint.scaling.z = 55;
        vm.surferpoint.rotation.x = Math.PI/2;
        vm.surferpoint.rotation.z = Math.PI/2;
        vm.surferpoint.material = vm.movedmaterial;

        //creation du point de rotation
        vm.rotationpoint = BABYLON.Mesh.CreateDisc("rotationpoint", 1, 100, vm.scene);
        vm.rotationpoint.diffuseColor = BABYLON.Color3.Red();
        vm.rotationpoint.position = new BABYLON.Vector3(0, 11, 0);
        vm.rotationpoint.scaling.x = 30;
        vm.rotationpoint.scaling.y = 30;
        vm.rotationpoint.scaling.z = 30;
        vm.rotationpoint.material = vm.fixedmaterial;
        vm.rotationpoint.rotation.x = Math.PI/2;

        var curvePoints = function(l, t) {
            var path = [];
            var step = l / t;
            var a = 5;
            for (var i = -l/2; i < l/2; i += step ) {
                path.push( new BABYLON.Vector3(10 * Math.sin(i*t / 400), 0, 10 * Math.cos(i*t / 400)) );
            }
            return path;
        };

        var curve = curvePoints(13, 100);

        //creation de l'arc de rotation
        vm.rotationarc = BABYLON.Mesh.CreateTube("rotationarc", curve, 0.1, 60, null, 0, vm.scene, true, BABYLON.Mesh.CAP_ALL);
        vm.rotationarc.material = vm.movedmaterial;
        vm.rotationarc.position = new BABYLON.Vector3(0, 6, 0);
        vm.rotationarscale = 1;
        vm.rotationarc.scaling.x = vm.rotationarscale;
        vm.rotationarc.scaling.y = vm.rotationarscale;
        vm.rotationarc.scaling.z = vm.rotationarscale;

        vm.wideline = BABYLON.Mesh.CreateBox("wideline", 1, vm.scene);
        vm.wideline.material = vm.fixedmaterial;
        vm.wideline.position = new BABYLON.Vector3(0, 4, 0);
        vm.wideline.scaling.x = 10;
        vm.wideline.scaling.y = 1;
        vm.wideline.scaling.z = 55;

        vm.loader.load();
    };

    //mesh remover function pour cacher les element non selected
    vm.meshshowhide = function(meshname, visibility, fin){
        var mesharemove = vm.scene.getMeshByName(meshname);
        mesharemove.setEnabled(visibility);

        //application du nouveau facteur position en fonction des configs selected
        if(visibility === false && fin === true){
            vm.facteurposition = ((vm.facteurposition * 3) - mesharemove.parent.facteurtotal) / 3;
            //vm.nombconfselec = vm.nombconfselec - 1;

            //remet a 0 la position de la config enleve
            var nameconfigposition = 'finselecposition' + mesharemove.typeconfig;
            vm[nameconfigposition] = 0;
        }
        if(visibility === true && fin === true){
            vm.facteurposition = ((vm.facteurposition * 3) + mesharemove.parent.facteurtotal) / 3;
            //vm.nombconfselec = vm.nombconfselec + 1;
        }
    };

    //select elem
    vm.selecElem = function(elem, finposition, erase){
        //recuperation de l'elem select dans la db
        var elemmodeltype = $filter('filter')(vm.modelsObj, elem, true);
        var elemmodel = $filter('filter')(elemmodeltype[0].elements, elem, true);

        if(elemmodel[0].type === 'fin'){
            if(finposition === 'single'){
                elemmodeltype[0].elements.forEach(function(m){
                    if(m.name === elem){
                        if(erase === true){
                            vm.singleValue = '';
                            vm.singleallreadyselected = false;
                            m.single = false;
                        }else{
                            m.single = true;
                        }
                        if(vm.singleallreadyselected != true){
                            if(vm.singleValue != ''){
                                vm.singleallreadyselected = true;
                            }
                            vm.meshshowhide(m.name + finposition + 'Child', m.single, true);
                        }else{
                            vm.meshshowhide(m.name + finposition + 'Child', m.single, true);
                        }
                    }else{
                        m.single = false;
                        vm.meshshowhide(m.name + finposition + 'Child', m.single, true);
                    }
                });
            }
            if(finposition === 'twin'){
                elemmodeltype[0].elements.forEach(function(m){
                    if(m.name === elem){
                        if(erase === true){
                            vm.twinValue = '';
                            vm.twinallreadyselected = false;
                            m.twin = false;
                        }else{
                            m.twin = true;
                        }
                        if(vm.twinallreadyselected != true){
                            if(vm.twinValue != ''){
                                vm.twinallreadyselected = true;
                            }
                            vm.meshshowhide(m.name + finposition + 'Child', m.twin, true);
                            vm.meshshowhide(m.name + finposition + 'Child2', m.twin, true);
                        }else{
                            vm.meshshowhide(m.name + finposition + 'Child', m.twin, true);
                            vm.meshshowhide(m.name + finposition + 'Child2', m.twin, true);
                        }
                    }else{
                        m.twin = false;
                        vm.meshshowhide(m.name + finposition + 'Child', m.twin, true);
                        vm.meshshowhide(m.name + finposition + 'Child2', m.twin, true);
                    }
                });
            }
            if(finposition === 'thruster'){
                elemmodeltype[0].elements.forEach(function(m){
                    if(m.name === elem){
                        if(erase === true){
                            vm.thrusterValue = '';
                            vm.thrusterallreadyselected = false;
                            m.thruster = false;
                        }else{
                            m.thruster = true;
                        }
                        if(vm.thrusterallreadyselected != true){
                            if(vm.thrusterValue != ''){
                                vm.thrusterallreadyselected = true;
                            }
                            vm.meshshowhide(m.name + finposition + 'Child', m.thruster, true);
                            vm.meshshowhide(m.name + finposition + 'Child2', m.thruster, true);
                        }else{
                            vm.meshshowhide(m.name + finposition + 'Child', m.thruster, true);
                            vm.meshshowhide(m.name + finposition + 'Child2', m.thruster, true);
                        }
                    }else{
                        m.thruster = false;
                        vm.meshshowhide(m.name + finposition + 'Child', m.thruster, true);
                        vm.meshshowhide(m.name + finposition + 'Child2', m.thruster, true);
                    }
                });
            }
        }else{
            if(elemmodel[0].type === 'board'){
                vm.boardselec = elemmodel[0].name;
                vm.boardselecNose = vm[vm.boardselec + 'nosepoint'];
                vm.boardselecTail = vm[vm.boardselec + 'tailpoint'];
                vm.boardselecWider = vm[vm.boardselec + 'widerpoint'];
                vm.boardselecWidel = vm[vm.boardselec + 'widelpoint'];
                vm.boardselecWidel = vm.boardselecWidel.getAbsolutePosition();
                vm.boardselecWider = vm.boardselecWider.getAbsolutePosition();
                vm.boardselecNose = vm.boardselecNose.getAbsolutePosition();
                vm.boardselecTail = vm.boardselecTail.getAbsolutePosition();
                vm.distancetailnose = BABYLON.Vector3.Distance(vm.boardselecNose, vm.boardselecTail);
                elemmodeltype[0].elements.forEach(function(m){
                    if(m.name === elem){
                        m.selected = true;
                        vm.meshshowhide(m.name + 'Child', m.selected, false);
                    }else{
                        m.selected = false;
                        vm.meshshowhide(m.name + 'Child', m.selected, false);
                    }
                });

                //actualisation des fin au changement de planche
                if(vm.singleValue != ''){
                    vm.actupoints(vm.sliderFinSinglevalue, 'single', vm.singleValue);
                }
                if(vm.twinValue != ''){
                    vm.actupoints(vm.sliderFinTwinvalue, 'twin', vm.twinValue);
                }
                if(vm.thrusterValue != ''){
                    vm.actupoints(vm.sliderFinThrustervalue, 'thruster', vm.thrusterValue);
                }
            }
        }
    };

    //move elem (element, direction, axe)
    vm.moveElem = function(elem, dir, axe){
        //recuperation de l'elem select dans la db
        var elemmodeltype = $filter('filter')(vm.modelsObj, elem, true);
        var elemmodel = $filter('filter')(elemmodeltype[0].elements, elem, true);

        if(elemmodel[0].selected){
            if(dir){
                var elem = vm.scene.getMeshByName(elemmodel[0].name);
                elem.position[axe] = elem.position[axe] + 1;
            }else{
                var elem = vm.scene.getMeshByName(elemmodel[0].name);
                elem.position[axe] = elem.position[axe] - 1;
            }
        }
    };

    //liste des elements selected
    vm.selectionList = function(){
        var tempTab = [];
        vm.modelsObj.forEach(function(m){
            m.elements.forEach(function (t) {
               if(t.selected === true) {
                   tempTab.push(t);
               }
            });
        });
        vm.elemSelected = tempTab;
    };

    vm.infoStepChange = function (dir) {
        if(dir == 'go'){
            vm.infoStep = vm.infoStep + 1;
        }
        if(dir == 'back'){
            vm.infoStep = vm.infoStep - 1;
        }
        vm.infoStepPrct = (vm.infoStep/vm.infoStepNb) * 100;
        vm.infoStepPrct = vm.infoStepPrct + '%';
    };

    vm.openModalCart = function(){
        vm.modalcart = !vm.modalcart;
    };

    vm.openModal = function(firstarrive, help){
        vm.modal = !vm.modal;
        var body = angular.element(document).find('body');
        body.toggleClass('modal-open', 'modal-backdrop');

        if(firstarrive === true){
            vm.helpmode = false;
            vm.firstarrive = false;
            vm.infoStep = 1;
            vm.infoStepNb = 7;
        }

        if(help === true){
            vm.infoStep = 8;
            vm.helpmode = true;
        }
    };

    vm.screenShot = function(){
        var size = { width: 600, height: 400};
        BABYLON.Tools.CreateScreenshotUsingRenderTarget(vm.engine, vm[vm.selectedcamera], size);
    };

    vm.switchinfosshow = function(show){
        vm.showinfos = show;
        if(show === true){
            vm.rotationarc.setEnabled(true);
            vm.rotationpoint.setEnabled(true);
            vm.surferpoint.setEnabled(true);
            vm.wideline.setEnabled(true);
        }else{
            vm.rotationarc.setEnabled(false);
            vm.rotationpoint.setEnabled(false);
            vm.surferpoint.setEnabled(false);
            vm.wideline.setEnabled(false);
        }
    };

    vm.detectsideoftheboard = function(position, facteurpos, yfin, zfin, slidervalue){

        //changement de position des aileron en fonction du facteur X
        var positionbord = [];

        var tabposboardnamey = vm.boardselec + 'posfintaby';
        var tabposboardnamez = vm.boardselec + 'posfintabz';

        vm.posfinheight = [];
        vm.posfinwidth = [];

        if(!slidervalue){
            slidervalue = 100;
        }

        //tableau des position pour trouver Y et Z de la position de la fin
        vm[tabposboardnamey].forEach(function(element) {
            if (slidervalue >= element.pourcpos){
                vm.posfinheight = element;
            }
        });
        vm[tabposboardnamez].forEach(function(element) {
            if (slidervalue >= element.pourcpos){
                vm.posfinwidth = element;
            }
        });

        //angle de fin
        var A = {
                x: position,
                y: 0
            };

        var B = {
                x: vm.boardselecWider.x,
                y: 0
            };

        var C = {
                x: position,
                y: vm.posfinheight.position.y
            };

        var AB = Math.sqrt(Math.pow(B.x-A.x,2)+ Math.pow(B.y-A.y,2));
        var BC = Math.sqrt(Math.pow(B.x-C.x,2)+ Math.pow(B.y-C.y,2));
        var AC = Math.sqrt(Math.pow(C.x-A.x,2)+ Math.pow(C.y-A.y,2));
        vm.anglesuppose = -(Math.acos((BC*BC+AB*AB-AC*AC)/(2*BC*AB)));

        positionbord[1] = vm.posfinheight.position.y;
        positionbord[2] = vm.posfinwidth.position.z * facteurpos;
        positionbord[3] = vm.anglesuppose;

        return positionbord;
    };

    vm.launchshowpart = function(){
        var boardtoanalize = vm.scene.getMeshByName(vm.boardselec + 'Child');
        vm.showPartitioning(boardtoanalize, 52.0, vm.scene);
    };

    vm.actupoints = function(slidervalue, type, sliderlabel){

        if(!slidervalue){
            slidervalue = 100;
        }

        if(type === 'single' && sliderlabel != ''){
            var pourcslider = slidervalue;
            slidervalue = slidervalue/100 * vm.boardselecTail.x;
            var elemname = sliderlabel + type;
            var meshamove = vm.scene.getMeshByName(elemname);
            meshamove.position.x = slidervalue;
            vm.finselecpositionsingle = meshamove.position.x * meshamove.facteurtotal;
            var newpos = vm.detectsideoftheboard(vm.finselecpositionsingle, meshamove.facteurcentrage, meshamove.position.y, meshamove.position.z, pourcslider);
            meshamove.position.y = newpos[1];
            meshamove.position.z = newpos[2];
            meshamove.rotation.z = newpos[3];
        }

        if(type === 'twin' && sliderlabel != ''){
            var pourcslider = slidervalue;
            slidervalue = slidervalue/100 * vm.boardselecTail.x;
            var elemname = sliderlabel + type;
            var meshamove = vm.scene.getMeshByName(elemname);
            meshamove.position.x = slidervalue;
            vm.finselecpositiontwin = meshamove.position.x * meshamove.facteurtotal;
            var newpos = vm.detectsideoftheboard(vm.finselecpositiontwin, meshamove.facteurcentrage, meshamove.position.y, meshamove.position.z, pourcslider);
            meshamove.position.y = newpos[1];
            meshamove.position.z = newpos[2];
            meshamove.rotation.z = newpos[3];

            elemname = sliderlabel + type + 'Child';
            meshamove = vm.scene.getMeshByName(elemname);
            var meshprincipal = meshamove.getAbsolutePosition();
            elemname = sliderlabel + type + 'Child2';
            meshamove = vm.scene.getMeshByName(elemname);
            meshprincipal.z = -meshprincipal.z;
            meshamove.setAbsolutePosition(meshprincipal);
        }

        if(type === 'thruster' && sliderlabel != ''){
            var pourcslider = slidervalue;
            slidervalue = slidervalue/100 * vm.boardselecTail.x;
            var elemname = sliderlabel + type;
            var meshamove = vm.scene.getMeshByName(elemname);
            meshamove.position.x = slidervalue;
            vm.finselecpositionthruster = meshamove.position.x * meshamove.facteurtotal;
            var newpos = vm.detectsideoftheboard(vm.finselecpositionthruster, meshamove.facteurcentrage, meshamove.position.y, meshamove.position.z, pourcslider);
            meshamove.position.y = newpos[1];
            meshamove.position.z = newpos[2];
            meshamove.rotation.z = newpos[3];

            elemname = sliderlabel + type + 'Child';
            meshamove = vm.scene.getMeshByName(elemname);
            var meshprincipal = meshamove.getAbsolutePosition();
            elemname = sliderlabel + type + 'Child2';
            meshamove = vm.scene.getMeshByName(elemname);
            meshprincipal.z = meshprincipal.z - (meshprincipal.z * 2);
            meshamove.setAbsolutePosition(meshprincipal);
        }

        if(type === 'surfer'){
            slidervalue = slidervalue/100 * vm.distancetailnose;
            slidervalue = vm.boardselecNose.x + slidervalue;
            vm.positionsurfer = slidervalue;
        }

        //recup la position X de chaque fins selected pour faire une position global sur X de la selection
        if(!vm.singleValue){
            vm.nbselectedsingle = 0;
            vm.finselecpositionsingle = 0;
            vm.selectedfinsingle = '';
        }else{
            vm.selectedfinsingle = vm.modelsObj[1].elements.filter(function(item) {
                return item.name === vm.singleValue;
            })[0];
            if(vm.selectedfinsingle.idproduit == 'longboard'){
                vm.selectedfinsingle.plug = 'US box';
            }else{
                vm.selectedfinsingle.plug = 'FCS';
            }
            vm.nbselectedsingle = 1;
        }
        if(!vm.twinValue){
            vm.nbselectedtwin = 0;
            vm.finselecpositiontwin = 0;
            vm.selectedfintwin = '';
        }else{
            vm.selectedfintwin = vm.modelsObj[1].elements.filter(function(item) {
                return item.name === vm.twinValue;
            })[0];
            if(vm.selectedfintwin.idproduit == 'longboard'){
                vm.selectedfintwin.plug = 'US box';
            }else{
                vm.selectedfintwin.plug = 'FCS';
            }
            vm.nbselectedtwin = 1;
        }
        if(!vm.thrusterValue){
            vm.nbselectedthruster = 0;
            vm.finselecpositionthruster = 0;
            vm.selectedfinthruster = '';
        }else{
            vm.selectedfinthruster = vm.modelsObj[1].elements.filter(function(item) {
                return item.name === vm.thrusterValue;
            })[0];
            if(vm.selectedfinthruster.idproduit == 'longboard'){
                vm.selectedfinthruster.plug = 'US box';
            }else{
                vm.selectedfinthruster.plug = 'FCS';
            }
            vm.nbselectedthruster = 1;
        }
        vm.nbselectedfins = vm.nbselectedsingle + vm.nbselectedtwin + vm.nbselectedthruster;
        vm.finglobalpos = (vm.finselecpositionsingle + vm.finselecpositiontwin + vm.finselecpositionthruster)/vm.nbselectedfins;
        //vm.finglobalpos = vm.finglobalpos * vm.facteurposition;

        //point de placement du surfer
        vm.surferpoint.position.x = vm.positionsurfer;

        vm.tailleboard = vm.boardselecTail.x - vm.boardselecNose.x;

        vm.ratiospeed =  Math.abs((vm.surferpoint.position.x - vm.boardselecWidel.x)/(vm.boardselecTail.x - vm.boardselecWidel.x)* 100);
        vm.ratiospeed = Math.abs(vm.ratiospeed - 100);
        vm.ratiospeedstyle = vm.ratiospeed + '%';

        vm.ratioturn =  Math.abs((vm.surferpoint.position.x - vm.finglobalpos)/(vm.tailleboard - vm.finglobalpos)* 100);

        vm.ratioturnstyle = Math.abs(vm.ratioturn-100) + '%';
        vm.ratiodirecstyle = Math.abs(Math.abs(vm.ratioturn-100)-100) + '%';

        //actualisation point de rotation
        //vm.rotationpoint.position.x = (vm.positionsurfer + vm.finglobalpos)/2;
        vm.rotationpoint.position.x = vm.finglobalpos;
        //actualisation arc de rotation
        vm.rotationarc.position.x = (vm.positionsurfer + vm.finglobalpos)/2;

        var boardselec = vm.scene.getMeshByName(vm.boardselec + 'Child');
        vm.wideline.scaling.z = (vm.boardselecWidel.z - vm.boardselecWider.z);
        vm.wideline.position = new BABYLON.Vector3(vm.boardselecWidel.x, 5, 0);
        var scalerotation = (vm.rotationarscale * (vm.ratioturn) + boardselec.facteursize)*3;
        vm.rotationarc.scaling.x = scalerotation;
        vm.rotationarc.scaling.y = 1;
        vm.rotationarc.scaling.z = scalerotation;
        vm.rotationarc.position.z = -(10 * vm.rotationarc.scaling.x);
        if(vm.rotationpoint.position.x < vm.surferpoint.position.x){
            vm.rotationarc.material = vm.wrongmaterial;
            vm.surferpoint.material = vm.wrongmaterial;
        }else{
            vm.rotationarc.material = vm.movedmaterial;
            vm.surferpoint.material = vm.movedmaterial;
        }
    };

    vm.facetcatch = function(){
        var elemname = vm.singleValue + 'singleInstance';
        var meshamove = vm.scene.getMeshByName(elemname);
        var projected = BABYLON.Vector3.Zero();
        meshamove.parent.position.x = 0;
        var facetlocals = meshamove.getFacetLocalPositions();
        meshamove._facetPositions.forEach(function(facetPos, index) {
            if(index === 50){
            }
        });
        var index = vm.board1.getClosestFacetAtLocalCoordinates(meshamove.position.x, meshamove.position.y, meshamove.position.z); // just the "facing" closest facet
        if (index != null) {
            var worldPos = vm.board1.getFacetPosition(index);// the world position of this facet

            // use the vector3 projected here ...
        }
    };

    vm.showPartitioning = function(mesh, scaling, scene) {
        if (!scaling) {
            scaling = 2.0 * mesh.partitioningBBoxRatio;
        }
        var octMat = new BABYLON.StandardMaterial('om', scene);
        octMat.alpha = 0.2;
        octMat.backFaceCulling = false;
        octMat.diffuseColor = BABYLON.Color3.Blue();
        octMat.specularColor = BABYLON.Color3.Black();
        var bInfo = mesh.getBoundingInfo();
        var plane;
        for (var x = 0; x <= mesh._partitioningSubdivisions; x++) {
            if (x == 0) {
                plane = BABYLON.MeshBuilder.CreatePlane("px0", {}, scene);
                plane.material = octMat;
            } else {
                plane = plane.clone("px"+x);
            }
            plane.position.x = (bInfo.minimum.x + x * (bInfo.maximum.x - bInfo.minimum.x) / mesh._partitioningSubdivisions) * mesh._partitioningBBoxRatio;
            plane.rotation.y = Math.PI / 2.0;
            plane.scaling.x = scaling * mesh._partitioningBBoxRatio;
            plane.scaling.y = scaling * mesh._partitioningBBoxRatio;
        }
        for (var y = 0; y <= mesh._partitioningSubdivisions; y++) {
            plane = plane.clone("py"+y);
            plane.position.x = 0.0;
            plane.position.y = (bInfo.minimum.y + y * (bInfo.maximum.y - bInfo.minimum.y) / mesh._partitioningSubdivisions) * mesh._partitioningBBoxRatio;
            plane.rotation.x = Math.PI / 2.0;
            plane.scaling.x = scaling * mesh._partitioningBBoxRatio;
            plane.scaling.y = scaling * mesh._partitioningBBoxRatio;
        }
    };

    vm.selecConfig = function(elem){
        //swicth de l'elem selected
        var elemmodeltype = $filter('filter')(vm.modelsObj, elem.name, true);
        var elemmodel = $filter('filter')(elemmodeltype[0].elements, elem.name, true);

        elemmodel[0].elements.forEach(function(m){
            if(m.name === elem.name){
                m.selected = true;
            }else{
                m.selected = false;
            }
        });

        //selection et attribution de la valeur de l'element de config
        if(elem.type == 'fixation'){
            vm.configfixations = elem.name;
        }
        if(elem.type == 'poids'){
            vm.configpoids = elem.facteur;
        }
        if(elem.type == 'style'){
            vm.configniveau = elem.facteur;
        }
        if(elem.type == 'vague'){
            vm.configvague = elem.facteur;
        }

        vm.configlogical();
    };

    vm.configlogical = function(){
        //fonction de logique du configurateur

        //premiere etape type de board
        //Different de longboard
        //attribut du nombre et type d'aileron en tab [central, twin, side]
        if(vm.boardselec !== 'board5'){
            if(vm.configfixations == 'Single'){
                vm.fixminmin = ['fin1', '', ''];
                vm.fixminmax = ['fin1', '', ''];
                vm.fixmiddlemin = ['fin2', '', ''];
                vm.fixmiddlemax = ['fin2', '', ''];
                vm.fixmaxmin = ['fin2', '', ''];
                vm.fixmaxmax = ['fin2', '', ''];
            }
            if(vm.configfixations == 'Twin'){
                vm.fixminmin = ['', '', 'fin1'];
                vm.fixminmax = ['', '', 'fin2'];
                vm.fixmiddlemin = ['', '', 'fin2'];
                vm.fixmiddlemax = ['', '', 'fin2'];
                vm.fixmaxmin = ['', '', 'fin2'];
                vm.fixmaxmax = ['', '', 'fin2'];
            }
            if(vm.configfixations == 'Thruster'){
                vm.fixminmin = ['fin1', '', ''];
                vm.fixminmax = ['fin2', '', ''];
                vm.fixmiddlemin = ['fin2', '', ''];
                vm.fixmiddlemax = ['', '', 'fin2'];
                vm.fixmaxmin = ['fin2', '', 'fin1'];
                vm.fixmaxmax = ['fin2', '', 'fin2'];
            }
            if(vm.configfixations == 'Quad'){
                vm.fixminmin = ['', '', 'fin1'];
                vm.fixminmax = ['', '', 'fin1'];
                vm.fixmiddlemin = ['', '', 'fin2'];
                vm.fixmiddlemax = ['', '', 'fin2'];
                vm.fixmaxmin = ['', 'fin1', 'fin2'];
                vm.fixmaxmax = ['', 'fin2', 'fin2'];
            }
            if(vm.configfixations == 'Five'){
                vm.fixminmin = ['fin1', '', ''];
                vm.fixminmax = ['fin2', '', ''];
                vm.fixmiddlemin = ['', '', 'fin2'];
                vm.fixmiddlemax = ['fin1', '', 'fin2'];
                vm.fixmaxmin = ['', 'fin1', 'fin2'];
                vm.fixmaxmax = ['fin1', 'fin1', 'fin2'];
            }
        }else{
            vm.fixminmin = ['fin3', '', ''];
            vm.fixminmax = ['fin3', '', ''];
            vm.fixmiddlemin = ['fin3', '', ''];
            vm.fixmiddlemax = ['fin3', '', ''];
            vm.fixmaxmin = ['fin3', '', ''];
            vm.fixmaxmax = ['fin3', '', ''];
        }

        //facteursurfeur
        vm.configfacteursurfeur = (vm.configpoids + vm.configniveau) / 2;

        //selection nombre
        if(vm.configfacteursurfeur <= 1){
            vm.configfacteur = 'fixmin';
        }
        if(vm.configfacteursurfeur > 1 && vm.configfacteursurfeur <= 2){
            vm.configfacteur = 'fixmiddle';
        }
        if(vm.configfacteursurfeur > 2){
            vm.configfacteur = 'fixmax';
        }

        //selection puissancenombre
        if(vm.configvague == 0){
            vm.configfacteur = vm.configfacteur + 'max';
        }
        if(vm.configvague == 1){
            vm.configfacteur = vm.configfacteur + 'min';
        }
        if(vm.configvague == 2){
            vm.configfacteur = vm.configfacteur + 'max';
        }
        if(vm.configvague == 3){
            vm.configfacteur = vm.configfacteur + 'max';
        }

        //application du tableau de config
        vm.selectionconfig = vm[vm.configfacteur];
        vm.singleValue = vm.selectionconfig[0];
        vm.twinValue = vm.selectionconfig[1];
        vm.thrusterValue = vm.selectionconfig[2];

        vm.selecElem(vm.singleValue, 'single', false);
        vm.actupoints(100, 'single', vm.singleValue);

        vm.selecElem(vm.twinValue, 'twin', false);
        vm.actupoints(90, 'twin', vm.twinValue);

        vm.selecElem(vm.thrusterValue, 'thruster', false);
        vm.actupoints(80, 'thruster', vm.thrusterValue);
    };

    //ouverture modal au debut
    vm.openModal();

    $document.ready(function(){

        // launch the scene when DOM is ready
        vm.launchsceneMain();

        vm.sliderFinSingle = new Slider("#singlefinslider", {
            min: 1,
            max: 100,
            value: vm.positionsinglefin,
            labelledby: 'singlefinslider-label'
        })
            .on('slide', function(slidervalue){
                $scope.$apply(function(){
                    vm.sliderFinSinglevalue = slidervalue;
                    vm.actupoints(slidervalue, 'single', vm.singleValue);
                });
            });

        vm.sliderFinTwin = new Slider("#twinfinslider", {
            min: 1,
            max: 100,
            value: vm.positiontwinfin,
            labelledby: 'twinfinslider-label'
        })
            .on('slide', function(slidervalue){
                $scope.$apply(function(){
                    vm.sliderFinTwinvalue = slidervalue;
                    vm.actupoints(slidervalue, 'twin', vm.twinValue);
                });
            });

        vm.sliderFinThruster = new Slider("#thrusterfinslider", {
            min: 1,
            max: 100,
            value: vm.positionthrusterfin,
            labelledby: 'thrusterfinslider-label'
        })
            .on('slide', function(slidervalue){
                $scope.$apply(function(){
                    vm.sliderFinThrustervalue = slidervalue;
                    vm.actupoints(slidervalue, 'thruster', vm.thrusterValue);
                });
            });

        vm.sliderSurfer = new Slider("#surferslider", {
            min: 1,
            max: 100,
            value: vm.positionsurfer,
            labelledby: 'surferslider-label'
        })
            .on('slide', function(slidervalue){
                $scope.$apply(function(){
                    vm.actupoints(slidervalue, 'surfer');
                });
            });
    });

    vm.validaddcart = function(){
        vm.validcart = true;
        var changevalid = function() {
            vm.validcart = false;
        };

        $timeout(changevalid, 2000);
    };

    vm.infoStepChange('go');

    vm.selectionList();
};
