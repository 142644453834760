'use strict';

global.angular = require('angular') ;
global._ = require('lodash');
global.$ = global.jQuery = require('jquery');

require('es6-promise/auto');
require('h5bp/h5bp');
require('jquery-ujs');
// require("@rails/ujs").start()

require('fyn/loading/font-loader');
require('fyn/loading/mobile');
//require('fyn/scroll-magic');

var app = require("fyn/app");

var locale = $('html').attr('lang');
angular.locale = locale;

angular.element(document).ready(function() {
  angular.bootstrap(document, [app.name], {
    strictDi: true
  });
});
