var angular = require('angular');

module.exports = angular.module('fyn.filters', [])
  .filter('ordinal', function() {
    return function(input) {
      switch(input) {
        case 1:
          return 'First';
          break;
        case 2:
          return 'Second';
          break;
        case 3:
          return 'Third';
          break;
        case 4:
          return 'Fourth';
          break;
        default:
          return ''
      }
    };
  }).filter('price', function() {
    return function(input) {
      return input ? input / 100 : input;
    };
  });
