'use strict';

var _ = require('lodash');

var CheckoutController = /*@ngInject*/ function CheckoutController($scope, order, token, OrdersService) {
  var vm = this;

  vm.order = order;

  var processing = false;
  vm.downloadInvoice = function() {
    if (processing) return;
    processing = true;
    OrdersService.getInvoice(order.id, token).then(function(response){
      window.location.href = response.url;
    }).finally(function(){
      processing = false;
    });
  }
};

CheckoutController.resolve = {
  order: /*@ngInject*/ function($http, $stateParams, OrdersService){
    return OrdersService.get($stateParams.id, $stateParams.token);
  },
  token: /*@ngInject*/ function($stateParams){
    return $stateParams.token;
  }
};

module.exports = CheckoutController;
