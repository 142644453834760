'use strict';

module.exports = /*@ngInject*/ function($timeout, CartService) {
  var vm = this;

  vm.cart = CartService.cart();

  vm.nextslideFire = false;

  $timeout(function(){
    vm.nextslideFire = true
  },4000);

  vm.mobileDetect = require('fyn/loading/mobile');
};
