'use strict';

module.exports = /*@ngInject*/ function($q, $http) {
  var countries;

  var countryInfos = {};

  function getAll(){
    var deferred = $q.defer();

    if (countries) {
      deferred.resolve(countries);
    } else {
      $http.get('/api/countries').then(function(response){
        countries = response.data;
        deferred.resolve(countries);
      })
    }

    return deferred.promise;
  }

  function get(code){
    var deferred = $q.defer();

    if (countryInfos[code]) {
      deferred.resolve(countryInfos[code]);
    } else {
      $http.get('/api/countries/'+code).then(function(response){
        countryInfos[code] = response.data;
        deferred.resolve(countryInfos[code]);
      })
    }

    return deferred.promise;
  }

  return {
    getAll: getAll,
    get: get
  }
};
