'use strict';

var _ = require('lodash');

var CheckoutInfosController = /*@ngInject*/ function CheckoutInfosController($scope, $stateParams, $state, $http, order, CountriesService, countries, countryInfo, CheckoutsService) {
  var vm = this;

  vm.order = order;

  vm.order.shipping_address = vm.order.shipping_address ||
    {
      first_name: '',
      last_name: '',
      company: '',
      address: '',
      apt: '',
      city: '',
      state: '',
      country: 'FR',
      postal_code: '' ,
      phone: ''
    };

  vm.countries = countries;
  vm.countryInfo = countryInfo;

  vm.countryChange = function countryChange(country) {
    if(!country)
      return;
    CountriesService.get(country).then(function(countryInfo){
      vm.countryInfo = countryInfo;
      vm.order.shipping_address.state = '';
      vm.order.shipping_address.postal_code = '';
    })
  };

  var submitting = false;
  var attempted  = false;

  vm.fieldClasses = function(field) {
    return {
      'has-focus': field.$focus,
      'has-error': (field.$touched || attempted) && field.$invalid//,
      //'has-success': field.$touched && field.$valid
    }
  };

  vm.hasError = function(field) {
    (field.$touched || attempted) && field.$invalid
  };

  vm.hasSuccess = function(field) {
    field.$touched && field.$valid
  };

  vm.submit = function(form) {
    if(!form.$valid) {
      attempted = true;
      return
    }

    if (submitting)
      return;
    submitting = true;

    CheckoutsService.save(vm.order.id, {
      email: vm.order.email,
      shipping_address_attributes: vm.order.shipping_address
    }).then(function(order){
      vm.order.id = order.id;
      vm.order.status = order.status;
      vm.order.total = order.total;
      vm.order.shipping = order.shipping;
      vm.order.taxes = order.taxes;
      $state.go('fyn.checkout.steps.payment', {id: vm.order.id});
    }).finally(function(){
      submitting = false;
    });

  }
};

CheckoutInfosController.resolve = {
  countries: /*@ngInject*/ function(CountriesService){
    return CountriesService.getAll();
  },
  countryInfo: /*@ngInject*/ function(CountriesService, order) {
    return CountriesService.get(order.shipping_address && order.shipping_address.country || 'FR');
  }
};

module.exports = CheckoutInfosController;
