'use strict';

var _ = require('lodash');

var CheckoutController = /*@ngInject*/ function CheckoutController($scope, order) {
  var vm = this;

  vm.order = order;
};

CheckoutController.resolve = {
  order: /*@ngInject*/ function($http, $stateParams, CheckoutsService){
    return CheckoutsService.get($stateParams.id);
  }
};

module.exports = CheckoutController;
