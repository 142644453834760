'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($scope) {

    var vm = this;
    vm.map = {
        center: {latitude: 8.768073, longitude: 8.265140},
        zoom: 2
    };
    vm.marker0 = {
          id: 0,
          coords: {
              latitude: 45.938463,
              longitude: 6.195060
          },
          options: {
              icon: '../images/local_office.png',
              title: "Annecy",
              draggable: false
          }
      };
    vm.marker1 = {
        id: 1,
        coords: {
            latitude: 45.532355,
            longitude: -73.551237
        },
        options: {
            icon: '../images/local_store.png',
            title: "Montréal",
            draggable: false
        }
    };
    vm.marker2 = {
        id: 2,
        coords: {
            latitude: 36.711732,
            longitude: -4.422816
        },
        options: {
            icon: '../images/local_store.png',
            title: "Màlaga",
            draggable: false
        }
    };
    vm.marker3 = {
        id: 3,
        coords: {
            latitude: 34.182676,
            longitude: -119.231727
        },
        options: {
            icon: '../images/local_store.png',
            title: "Oxnard",
            draggable: false
        }
    };
    vm.marker4 = {
        id: 4,
        coords: {
            latitude: -32.120360,
            longitude: 115.761010
        },
        options: {
            icon: '../images/local_store.png',
            title: "Coogee",
            draggable: false
        }
    };
    vm.marker5 = {
        id: 5,
        coords: {
            latitude: -22.009366,
            longitude: 15.225116
        },
        options: {
            icon: '../images/local_store.png',
            title: "Erongo",
            draggable: false
        }
    };
    vm.marker6 = {
        id: 6,
        coords: {
            latitude: 21.304948,
            longitude: -157.817624
        },
        options: {
            icon: '../images/local_store.png',
            title: "Honolulu",
            draggable: false
        }
    };
    vm.marker7 = {
        id: 7,
        coords: {
            latitude: 48.121834,
            longitude: 11.572726
        },
        options: {
            icon: '../images/local_store.png',
            title: "Munich",
            draggable: false
        }
    };
    vm.marker8 = {
        id: 8,
        coords: {
            latitude: 44.846183,
            longitude: -0.569664
        },
        options: {
            icon: '../images/local_store.png',
            title: "Bordeaux",
            draggable: false
        }
    };
    vm.marker9 = {
        id: 9,
        coords: {
            latitude: 43.303194,
            longitude: 5.378039
        },
        options: {
            icon: '../images/local_store.png',
            title: "Marseille",
            draggable: false
        }
    };
    vm.marker10 = {
        id: 10,
        coords: {
            latitude: 43.483993,
            longitude: -1.567638
        },
        options: {
            icon: '../images/local_store.png',
            title: "Biarritz",
            draggable: false
        }
    };
    vm.marker11 = {
        id: 11,
        coords: {
            latitude: 44.642339,
            longitude: 10.923790
        },
        options: {
            icon: '../images/local_store.png',
            title: "Modena",
            draggable: false
        }
    };
    vm.marker12 = {
        id: 12,
        coords: {
            latitude: -23.814658,
            longitude: -45.415074
        },
        options: {
            icon: '../images/local_store.png',
            title: "São Sebastião",
            draggable: false
        }
    };
    vm.marker13 = {
        id: 13,
        coords: {
            latitude: 40.870326,
            longitude: -73.916558
        },
        options: {
            icon: '../images/local_store.png',
            title: "New York City",
            draggable: false
        }
    };
    vm.marker14 = {
        id: 14,
        coords: {
            latitude: 14.637501,
            longitude: -61.010259
        },
        options: {
            icon: '../images/local_store.png',
            title: "Martinique",
            draggable: false
        }
    };
    vm.marker15 = {
        id: 15,
        coords: {
            latitude: 45.580589,
            longitude: 4.814019
        },
        options: {
            icon: '../images/local_store.png',
            title: "Chasse-sur-Rhône",
            draggable: false
        }
    };
    vm.marker16 = {
        id: 16,
        coords: {
            latitude: 44.068769,
            longitude: 1.520836
        },
        options: {
            icon: '../images/local_store.png',
            title: "Nègrepelisse",
            draggable: false
        }
    };
    vm.marker17 = {
        id: 17,
        coords: {
            latitude: 46.419221,
            longitude: 2.952687
        },
        options: {
            icon: '../images/local_store.png',
            title: "Chavenon",
            draggable: false
        }
    };
    vm.marker18 = {
        id: 18,
        coords: {
            latitude: 45.144285,
            longitude: -0.837855
        },
        options: {
            icon: '../images/local_store.png',
            title: "Saint-Laurent-Médoc",
            draggable: false
        }
    };
    vm.marker19 = {
        id: 19,
        coords: {
            latitude: 47.655625,
            longitude: -0.511581
        },
        options: {
            icon: '../images/local_store.png',
            title: "Juvardeil",
            draggable: false
        }
    };
    vm.marker20 = {
        id: 20,
        coords: {
            latitude: 48.749061,
            longitude: 1.918215
        },
        options: {
            icon: '../images/local_store.png',
            title: "Coignières",
            draggable: false
        }
    };
    vm.marker21 = {
        id: 21,
        coords: {
            latitude: 48.860193,
            longitude: 2.336912
        },
        options: {
            icon: '../images/local_store.png',
            title: "Paris",
            draggable: false
        }
    };
    vm.marker22 = {
        id: 22,
        coords: {
            latitude: 47.217218,
            longitude: -1.536003
        },
        options: {
            icon: '../images/local_store.png',
            title: "Nantes",
            draggable: false
        }
    };
    vm.marker23 = {
        id: 23,
        coords: {
            latitude: 43.874788,
            longitude: -1.303371
        },
        options: {
            icon: '../images/local_store.png',
            title: "Léon",
            draggable: false
        }
    };
    vm.marker24 = {
        id: 24,
        coords: {
            latitude: 44.783393,
            longitude: -1.172234
        },
        options: {
            icon: '../images/local_store.png',
            title: "Lège-Cap-Ferret",
            draggable: false
        }
    };
    vm.marker25 = {
        id: 25,
        coords: {
            latitude: 43.595955,
            longitude: 1.439705
        },
        options: {
            icon: '../images/local_store.png',
            title: "Toulouse",
            draggable: false
        }
    };
    vm.marker26 = {
        id: 26,
        coords: {
            latitude: 43.422266,
            longitude: -1.611077
        },
        options: {
            icon: '../images/local_store.png',
            title: "Guéthary",
            draggable: false
        }
    };
    vm.marker27 = {
        id: 27,
        coords: {
            latitude: 43.392385,
            longitude: -1.657126
        },
        options: {
            icon: '../images/local_store.png',
            title: "Saint-Jean-de-Luz",
            draggable: false
        }
    };
};
