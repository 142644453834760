'use strict';

var _ = require('lodash');

var CheckoutProcessController = /*@ngInject*/ function CheckoutProcessController($scope, $state, $timeout, CheckoutsService, order, CartService) {
  var vm = this;

  vm.order = order;

  function wait_for_processing(order, retries){
    if (retries == 0) {
      order.status = 'failed';
      $state.go('fyn.checkout.success', {id: vm.order.id});
      return
    }

    CheckoutsService.status(order.id)
      .then(function(response){
        debugger;
        order.status = response.status;
        if (order.status == 'processing'){
          $timeout(function() { wait_for_processing(order, retries - 1) }, 500);
        } else if(order.status == 'ready'){
          CartService.reset();
          $state.go('fyn.checkout.success', {id: vm.order.id});
        } else if (order.status == 'penging' || order.status == 'failed'){
          $state.go('fyn.checkout.steps.payment', {id: order.id}, {location: 'replace'});
        } else if (order.status == 'initialized'){
          $state.go('fyn.checkout.steps.infos', {id: order.id});
        }
      }).catch(function(error){
        $timeout(function() { wait_for_processing(order, retries - 1) }, 500);
      })
  }

  wait_for_processing(order, 30);
};

module.exports = CheckoutProcessController;
