'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($localStorage) {
  var _cart;

  var _default_cart = {
    version: 6,
    items: [],
    subtotal: 0
  };

  function cartMigration() {
    _cart = _default_cart;
    save();
  }

  function init() {
    if (_cart)
      return _cart;
    _cart = $localStorage.cart;
    if (!_cart) {
      $localStorage.cart = _cart = _default_cart;
    }
    if(_cart.version != _default_cart.version)
      cartMigration();
    return _cart
  }

  function addItem(product, prices, quantity) {
    if (!_cart)
      init();

    var item = _.find(items(), function(item){
      return (item.product_id == product.id) && (item.option == product.option)
    });

    if (!item) {
      item = {
        position: items.length + 1,
        product_id: product.id,
        product: product,
        option: product.option,
        quantity: 0,
        subtotal: 0,
        price: product.price,
        priceKind: product.priceKind
      };
      _cart.items.push(item);
    }
    updateItemQuantity(item, quantity || 1, prices);
    save();
  }

  function updateItemQuantity(item, quantity, prices) {
    item.quantity += quantity;
    if (item.quantity <= 0)  {
      _.remove(_cart.items, item);
    }

    calculateItemsSubtotals(prices);
    calculateCartSubtotal();

    save();
  }

  function calculateItemsSubtotals(prices) {
     var kindQty = {};
     var kindPrice = {};

    _.forEach(items(), function(item){
      if (item.priceKind) {
        kindQty[item.priceKind] || (kindQty[item.priceKind] = 0);
        kindQty[item.priceKind] += item.quantity;
      }
    });

    _.forEach(kindQty, function(qty, kind){
      var kindPrices = prices[kind];
      if (!kindPrices) return false;

      _.forEach(kindPrices, function(price) {
        if (qty >= price[0]) {
          kindPrice[kind] = price[1];
        } else {
          return false;
        }
      });

    });


    _.forEach(items(), function(item){
      if (item.priceKind) {
        item.price = kindPrice[item.priceKind];
      }
      item.subtotal = item.price * item.quantity;
    });
  }

  function calculateCartSubtotal() {
    _cart.subtotal = _.sum(items(), function(item){
      return item.subtotal;
    });
  }

  function items(){
    if (!_cart)
      init();

    return _cart.items;
  }

  function cart(){
    if (!_cart)
      init();

    return _cart
  }

  function save(){
    if (!_cart)
      init();
    $localStorage.cart = _cart;
  }

  function reset(){
    $localStorage.cart = _cart = undefined;
    init();
  }

  return {
    init: init,
    addItem: addItem,
    updateItemQuantity: updateItemQuantity,
    items: items,
    cart: cart,
    save: save,
    reset: reset
  }
};
