'use strict';

var _ = require('lodash');

module.exports = /*@ngInject*/ function($scope, $stateParams, $state, CartService) {
  var vm = this;
  vm.option = $stateParams.option;

  vm.products = $scope.$parent.products;
  if(!vm.products)
    return;
  vm.product = _.find(vm.products, 'id', $stateParams.option);
  vm.urlpackshot = vm.product.image;

  vm.addItem = $scope.$parent.vm.addItem;
};
