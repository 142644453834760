require('ngStorage');

require('angular-animate');
require('angular-simple-logger');
require('angular-google-maps');
require('angular-spinners');

var angular = require('angular');
var configs = require('./configs');
var routes = require('./routes');
var filters = require('./filters');
var directives = require('./directives');
var services = require('./services');

module.exports = angular.module('fyn', [
  'ngStorage',
  'ngAnimate',
  'angularSpinners',
  'uiGmapgoogle-maps',
  configs.name,
  routes.name,
  filters.name,
  directives.name,
  services.name
]);
