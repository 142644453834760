var angular = require('angular');

require("angular-ui-router");

var _ = require('lodash');

module.exports = angular.module('fyn.routes', ['ui.router'])
  .config(function ($locationProvider, $urlRouterProvider, $stateProvider){

    $locationProvider.html5Mode({
      enabled: true
    });

    $urlRouterProvider.when('/en',function ($state) {
      window.location.reload();
    });
    $urlRouterProvider.when('/fr',function ($state) {
      window.location.reload();
    });
    $urlRouterProvider.when('/es',function ($state) {
      window.location.reload();
    });

    // $urlRouterProvider.when('/store',function ($state) {
    //   if($state.current.name == 'fyn.store.genome') return false;
    //   $state.go('fyn.store.genome', { location: 'replace' })
    // });

    $urlRouterProvider.otherwise('/');

    $stateProvider.state('accueil', {
      url: '/',
      templateUrl: '/templates/accueil.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/accueil-controller')
    });

    $stateProvider.state('fyn.dynamic', {
      url: '/dynamic',
      templateUrl: '/templates/fyn/pages/dynamic.html'
    });

    $stateProvider.state('fyn.static', {
      url: '/static',
      templateUrl: '/templates/fyn/pages/static.html'
    });

    $stateProvider.state('fyn', {
      abstract: true,
      url: '',
      templateUrl: '/templates/fyn/base.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/base-controller')
    });

    $stateProvider.state('fyn.resources', {
       url: '/resources',
       templateUrl: '/templates/fyn/pages/resources.html'
    });

    $stateProvider.state('fyn.designer', {
      url: '/designer',
      templateUrl: '/templates/fyn/pages/designer.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/designer-controller'),
      onExit: function(){
          var body = angular.element(document).find('body');
          body.removeClass('modal-open', 'modal-backdrop');
      }
    });

    $stateProvider.state('fyn.aboutus', {
        url: '/aboutus',
        templateUrl: '/templates/fyn/pages/aboutus.html'
    });

    $stateProvider.state('fyn.adac', {
      url: '/adac',
      templateUrl: '/templates/fyn/pages/adac.html'
    });

    $stateProvider.state('fyn.shaper', {
      url: '/shaper',
      templateUrl: '/templates/fyn/pages/shaper.html'
    });

    $stateProvider.state('fyn.retailers', {
      url: '/retailers',
      templateUrl: '/templates/fyn/pages/retailers.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/retailers-controller')
    });

    $stateProvider.state('fyn.store', {
      abstract: true,
      url: '/store',
      templateUrl: '/templates/fyn/store/store.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/store-controller')
    });

    $stateProvider.state('fyn.store.all', {
      url: '',
      templateUrl: '/templates/fyn/store/all.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/store-all-controller')
    });

    $stateProvider.state('fyn.store.product', {
      url: '/{option:longboardset|newfish|thrusterclassic|quadpuissant|summersecure|longboarddynamic|classic2|pro2|static|static2|staticpro|staticpro2|longboard}',
      templateUrl: '/templates/fyn/store/product.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/store-product-controller'),
      onEnter: function($state, $stateParams) {
        if (!_.includes(['longboardset', 'newfish', 'thrusterclassic', 'quadpuissant', 'summersecure', 'longboarddynamic', 'classic2', 'pro2', 'static', 'static2', 'staticpro', 'staticpro2', 'longboard'], $stateParams.option))
          $state.go('fyn.store.all', {location: 'replace'});
      }
    });

    $stateProvider.state('fyn.cart', {
      url: '/cart',
      templateUrl: '/templates/fyn/cart/cart.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/cart-controller')
    });

    $stateProvider.state('fyn.checkout', {
      abstract: true,
      url: '/checkout/:id',
      template: '<div ui-view></div>',
      controllerAs: 'vm',
      controller: require('fyn/controllers/checkout-controller'),
      resolve: require('fyn/controllers/checkout-controller').resolve
    });

    $stateProvider.state('fyn.checkout.steps', {
      abstract: true,
      url: '',
      templateUrl: '/templates/fyn/checkout/checkout_steps_base.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/checkout-steps'),
      data: {
        checkoutData: {
          countryForms: {}
        }
      }
    });

    $stateProvider.state('fyn.checkout.steps.infos', {
      url: '/information',
      templateUrl: '/templates/fyn/checkout/infos.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/checkout-infos-controller'),
      resolve:require('fyn/controllers/checkout-infos-controller').resolve,
      onEnter: checkOrderState
    });

    $stateProvider.state('fyn.checkout.steps.payment', {
      url: '/payment?error',
      templateUrl: '/templates/fyn/checkout/payment.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/checkout-payment-controller'),
      resolve:require('fyn/controllers/checkout-payment-controller').resolve,
      onEnter: checkOrderState
    });

    $stateProvider.state('fyn.checkout.process', {
      url: '/process',
      templateUrl: '/templates/fyn/checkout/process.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/checkout-process-controller'),
      onEnter: checkOrderState
    });

    $stateProvider.state('fyn.checkout.success', {
      url: '/success',
      templateUrl: '/templates/fyn/checkout/success.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/checkout-success-controller'),
      onEnter: checkOrderState
    });

    function /*@ngInject*/ checkOrderState($state, order) {
      if ((order.state == 'ready') && this.name != 'fyn.checkout.success') {
        $state.go('fyn.checkout.success', {id: order.id}, {location: 'replace'});
      } else if (order.state == 'processing' && this.name != 'fyn.checkout.process') {
        $state.go('fyn.checkout.process', {id: order.id}, {location: 'replace'});
      } else if ((order.state == 'pending' || order.state == 'failed') && this.name != 'fyn.checkout.steps.payment') {
        $state.go('fyn.checkout.steps.payment', {id: order.id}, {location: 'replace'});
      } else if (order.state == 'initialized' && (this.name != 'fyn.checkout.steps.infos' && this.name != 'fyn.checkout.steps.payment')) {
        $state.go('fyn.checkout.steps.infos', {id: order.id});
      }
    }

    $stateProvider.state('fyn.order', {
      url: '/orders/:id/:token',
      templateUrl: '/templates/fyn/orders/show.html',
      controllerAs: 'vm',
      controller: require('fyn/controllers/orders-controller'),
      resolve: require('fyn/controllers/orders-controller').resolve
    });
  }
);
